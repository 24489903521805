import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import LetsBegin from '../components/common/LetsBegin'
import Loading from '../components/common/Loading'
import styles from '../styles/Lesson.module.css'
import { playSound } from '../utils/playsound'

import { getAudioList, initiateVolumeMeter, pauseAll, waitForVoice } from '../utils/audios'
import { LessonTitles } from '../const/lessonTitles'
import {
    activateStudent,
    getCoins,
    initVoice,
    lessonResult, pingLesson,
    startLesson,
    submitLesson,
    submitLessonPractice,
    uploadToS3,
    watchLessonSubmission
} from '../resources/api'
import wait, { delayAll } from "../utils/wait";
import Button from "../components/common/Button";
import clsx from "clsx";
import { InstructionMark } from "../utils/instruction";
import InstructionContext from "../context/InstructionContext";
import { Lessons } from "../const/lessons";
import useCountDown from "../utils/countdown";
import LessonIntroduction from "../components/common/LessonIntroduction";
import { useWindowSize } from "react-use";
import CoinsContainer from "../components/coins/CoinsContainer";
import ReviewPage from "../components/review/Review";
import ResultPage from "../components/common/ResultPage";
import { CorrectIcon, RecordIcon, WrongIcon } from "../resources/svgs";
import Lottie from "react-lottie";
import * as processingAnimation from "../lottie/processing.json";
import { formatTime } from "../utils/common";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../components/common/Modal";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import StatusContext from "../context/StatusContext";
import FlipNumbers from "react-flip-numbers";
import { initiatePlusTrainServo } from "../utils/plusTrainServo";
import { getApplicableLessonID } from '../components/review/Type1'
import UserContext from '../context/UserContext'
import { trainingLessons } from '../const/trainne'

const lessonDatas = require("../const/lessons.json")


var speechConfig = SpeechSDK.SpeechConfig.fromSubscription("3e60cc9987ec41418be6de7cf1905fa2", "eastus");
speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "5000");
speechConfig.setProperty(SpeechSDK.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "5000");

speechConfig.setProperty("SPEECH-PhraseTimeout", "100000");

// Set some properties to disable all timeout on silence
// function useInitializer({lessonId, _lessonId, extras}) {
//     const [page, setPage] = useState(null)
//     const [result, setResult] = useState({isLoading: true});
//     const [coinBucketsData, setCoinBucketsData] = useState({coinBuckets: []})
//     const startLessonCb = useCallback((lessonId, extras) => {
//         setResult({isLoading: true})
//         setPage(null)
//         alert(JSON.stringify([lessonId, extras]))
//         startLesson(lessonId, extras).then(res => {
//             if (!res.data.otherRequirements) {
//                 getCoins(res.data.trialId).then(coins => {
//                     setCoinBucketsData(coins)
//
//                     setResult({isLoading: false, ...res.data})
//
//                 })
//             } else {
//                 window.switchToErrorPage("")
//                 //setPlayDetails({...res.data,})
//             }
//
//
//         })
//     }, []);
//     useEffect(() => {
//         watchLessonSubmission((updatedBucket, isCoinEarned, coinGroupId) => {
//             setCoinBucketsData({coinBuckets: updatedBucket, isCoinEarned, coinGroupId})
//         })
//     }, [])
//     useEffect(() => {
//
//         startLessonCb(lessonId, extras)
//     }, [lessonId, extras])
//     return {
//         ...result, coinBucketsData, restartLesson() {
//             startLessonCb(_lessonId)
//         },
//         page, setPage
//     };
// }
function matchStrings(a, b, handleEndings = true) {
    // initialize an empty object to store the results
    let result = {};
    // initialize two pointers to track the indices of a and b
    let i = 0;
    let j = 0
    // loop through the list a until the end
    var lastRead = null;
    while ((i < b.length) && (i < a.length)) {
        lastRead = `${a[i]}_${i}`.trim().replace(/[^A-Za-z0-9_ -]+/, '').toLowerCase();
        result[`${a[i]}${i}`] = "read";
        i++;
    }
    return [result, lastRead]
}
async function getMicClip({ microPhoneConfig: _micConfig, setStatus, setHasRecorded, audioStreamBegan, closeStream }) {
    const microPhoneConfig = { waitTime: 6000, timeOut: 2000, wrLength: 3, type: "norepeat", ...(_micConfig || {}) }
    console.log({ called: "getMicClip", microPhoneConfig })
    return await waitForVoice(microPhoneConfig.wrLength, 1, microPhoneConfig.type, microPhoneConfig.waitTime, {
        speechTimeOut: microPhoneConfig.timeOut,
        listening() {
            setStatus("recording")
            setHasRecorded && setHasRecorded(true)
        },
        empty() {
            setStatus("recording")
        },
        audioStreamBegan,
        closeStream
    });
}

function useValidity({ lessonId, trialId, trialType, triggerPausing, isReview, countDown, stage, setCurrent, restartLesson, revisionCb, lessonDetails, current, countDownState, countDownAmount, reset, countDownId, stop }) {
    const [status, setStatus] = useState(null)
    const [optionalGrowth, setIsOptionalGrowth] = useState(false)
    const [isEnded, setIsEnded] = useState(false)
    const [repeatId, setRepeatId] = useState(0)
    const silences = useRef(0)
    const [nextItem, setNextItem] = useState(null)
    const [nextPage, setNextPage] = useState(null)
    const [showsStatus, setShowsStatus] = useState(true)
    const [hasRecorded, setHasRecorded] = useState(false)
    const [sentenceResults, setSentenceResults] = useState(null)
    const [phonemeResults, setPhonemeResults] = useState(null)
    const [isPractice, setIsPractice] = useState(false)
    const [silenceBuzzing, setSilenceBuzzing] = useState()

    //bek
    const isCorrected = useRef(true)
    const tryAgainRef = useRef(false)

    const s3FieldsRef = useRef()
    const audioBlob = useRef()
    const metaBlob = useRef()
    const textBlob = useRef()

    const wrongCount = useRef(0)
    const navigate = useNavigate();
    const [practiceDetails, setPracticeDetails] = useState({})
    const startTime = useRef()

    const stageInfo = useMemo(() => stage && lessonDetails.stages[stage], [stage, lessonDetails])

    const itemDetails = useMemo(() => {
        if (current) {
            return lessonDetails.data[current]
        } else {
            return null
        }
    }, [current, lessonDetails])

    // const resetStatus = useCallback(()=>{
    //
    // }, [status, lessonDetails, lessonId,current, stage, stageInfo])



    const microphoneOperation = useCallback(async () => {

        s3FieldsRef.current = await initVoice(lessonId, current)
        var recognizer;
        var recognizingText = itemDetails.text;
        if (itemDetails?.type == "sentence") {
            if (typeof itemDetails.text != "string") {
                recognizingText = itemDetails.text.join(" ")
            }
            setSentenceResults((results) => ({ ...results, [current]: null }))
            recognizingText = recognizingText.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()

        }


        const blob = await getMicClip({
            microPhoneConfig: stageInfo.microPhoneConfig,
            setStatus,
            setHasRecorded,
            audioStreamBegan(stream, endTheStream) {
                startTime.current = new Date()
                if (itemDetails?.type == "sentence") {

                    var audioConfig = SpeechSDK.AudioConfig.fromStreamInput(stream);
                    recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
                    const phraseList = SpeechSDK.PhraseListGrammar.fromRecognizer(recognizer);
                    var mainTarget = recognizingText.split(" ");
                    for (let i = 0; i < mainTarget.length; i++) {
                        phraseList.addPhrase(mainTarget[i]);
                    }

                    // const conn = SpeechSDK.Connection.fromRecognizer(recognizer);
                    // conn.setMessageProperty("speech.context", "phraseDetection", {
                    //     "INTERACTIVE": {
                    //         "segmentation": {
                    //             "mode": "custom",
                    //             "segmentationSilenceTimeoutMs": 5000
                    //         }
                    //     },
                    //     mode: "Interactive"
                    // });

                    // const pronunciationAssessmentConfig = new SpeechSDK.PronunciationAssessmentConfig(recognizingText,
                    //     SpeechSDK.PronunciationAssessmentGradingSystem.HundredMark,
                    //     SpeechSDK.PronunciationAssessmentGranularity.Word,
                    //     "true")
                    // pronunciationAssessmentConfig.properties.setProperty(SpeechSDK.PropertyId.PronunciationAssessment_EnableMiscue, "true")
                    // pronunciationAssessmentConfig.applyTo(recognizer)

                    var totalProcessedTextIs = []
                    setSentenceResults((results) => ({ ...results, [current]: null }))
                    var noticedWords = {};


                    for (let i = 0; i < recognizingText.length; i++) {
                        var word = mainTarget[i]
                        noticedWords[i] = {
                            word,
                            noticed: false
                        }
                    }
                    var allTexts = []
                    var currentTextItem = "";
                    var allTextPrevMerged = ""
                    recognizer.recognizing = function (s, e) {

                        // handle intermediate results
                        var data = JSON.parse(e.result.json)

                        // console.log("RECOGNIZing: Text=",data);

                        if (typeof (e.result.text) == "string") {
                            try {
                                var prctext = e.result.text.trim().replace(/[^A-Za-z0-9_ -]+/ig, '').toLowerCase()
                                currentTextItem = prctext

                                var recognizedText = ([allTextPrevMerged, prctext]).filter(itm => itm?.length > 0).join(" ")
                                var recognizedWords = recognizedText.split(" ")


                                var [sResultItem, lastRead] = matchStrings(mainTarget, recognizedWords)
                                if (lastRead) {
                                    // document.getElementById(lastRead).scrollIntoView({
                                    //     behavior: "smooth",
                                    //     block: "center",
                                    //     inline: "start"
                                    // })
                                }
                                setSentenceResults((results) => ({
                                    ...results, [current]: sResultItem
                                }))
                            } catch (e) {
                                console.error(e)
                            }
                        }
                        // handle intermediate results
                    };

                    var totalProcessedTexts = []
                    var totalScorings = []
                    var totalWords = []

                    recognizer.recognized = function (s, e) {
                        try {
                            var data = JSON.parse(e.result.json)
                            allTexts.push(currentTextItem)
                            currentTextItem = null;
                            allTextPrevMerged = allTexts.filter(itm => itm?.length > 0).join(" ")
                            //console.log( data)
                            if (data.RecognitionStatus == "Success") {
                                //console.log("Finishing recognition...")

                                // var nBest = data.NBest[0];
                                // var pronunciation = nBest.PronunciationAssessment
                                // // totalScorings.push([pronunciation.AccuracyScore,  pronunciation.CompletenessScore, pronunciation.FluencyScore, pronunciation.PronScore])
                                // totalWords.push(...nBest.Words.filter(word=>word.PronunciationAssessment.AccuracyScore > 93).map((word, i)=>({index: i, word: word.Word,
                                //     score:word.PronunciationAssessment.AccuracyScore ,
                                //     error: word.PronunciationAssessment.ErrorType})))
                                // if(data.DisplayText?.length > 0){
                                //     totalProcessedTexts.push(data.DisplayText)
                                //
                                // }


                                // var [accuracy, complete, fluency, pronunciationScore] = totalScorings.reduce((prev, curr)=>{
                                //     return [prev[0] + curr[0], prev[1] + curr[1],prev[2] + curr[2],prev[3] + curr[3]]
                                // }, [0,0,0,0])
                                metaBlob.current = {
                                    text: allTextPrevMerged,
                                    duration: metaBlob.current?.duration ? metaBlob.current.duration + e.result.duration : e.result.duration,
                                    wcpm: ((allTextPrevMerged.split(" ").length / (e.result.duration / 10000000)) * 60)
                                }

                                if ((allTextPrevMerged.split(" ").slice(-1) == recognizingText.split(" ").slice(-1))) {
                                    endTheStream()
                                }

                            } else if (data.RecognitionStatus == "EndOfDictation") {
                                endTheStream()
                            }

                        } catch (e) {
                            console.error(e)
                        }

                    };
                    recognizer.canceled = function (s, e) {
                        // handle cancelation
                        console.log("CANCELED: Reason=" + e.reason);
                        endTheStream()
                    };
                    recognizer.startContinuousRecognitionAsync()


                }

            },
            async closeStream() {
                if (itemDetails?.type == "sentence") {
                    await (new Promise((resolve, reject) => {
                        recognizer.stopContinuousRecognitionAsync(resolve, reject)
                    }))
                }
            }
        })
        await wait(1)
        if (itemDetails?.type == "sentence") {
            await (new Promise((resolve, reject) => {
                recognizer.stopContinuousRecognitionAsync(resolve, reject)

            }))
            recognizer.close()
        }
        if (blob) {
            if (blob == "done") {
                setStatus("started")

            } else {
                audioBlob.current = blob;
                setStatus("processing")

            }
        }
        else {
            setStatus(null)

            if (silences.current < 1) {
                if (stageInfo.silencePrompt) {
                    await soundManager.playFrom("status", stageInfo.silencePrompt)
                }
                silences.current += 1
                setStatus("started")
            } else if (silences.current == 1) {
                if (stageInfo.silenceBuzz) {
                    setSilenceBuzzing(true)
                    await wait(15000)
                }
                silences.current += 1
                setStatus("started")

            } else {
                setStatus("started")
                // window.location.reload()
                // audioBlob.current = blob;
                // setStatus("processing")
            }

        }
    }, [lessonId, current, stageInfo, itemDetails]);
    const practiceMicrophoneOperation = useCallback(async (lessonId, key) => {
        const s3Fields = await initVoice(lessonId, `tests/${key}`)
        s3FieldsRef.current = s3Fields
        //const blob = await mockAudio()
        const blob = await getMicClip({ microPhoneConfig: { type: "repeat" }, setStatus, setHasRecorded })
        if (blob) {
            if (blob == "done") {
                setStatus("started")

            } else {
                audioBlob.current = blob;
                setStatus("processing")
            }
        } else {
            setStatus(null)
        }
    }, []);
    const registerMicrophone = useCallback((microphone) => {
        if (microphone) {
            setShowsStatus(false)
            return {
                onClick: microphoneOperation,
                micEnabled: (status === "started") && ((countDownState != "running") || ((countDown > 1))),
                status
            }
        } else {
            return {}
        }

    }, [status, microphoneOperation, countDownState, countDown])
    const playInstructionsOptionally = useCallback(async () => {
        if (itemDetails.playBefore && (!(repeatId > 0) || lessonId === "3")) {
            var pbfs = Array.isArray(itemDetails.playBefore) ? itemDetails.playBefore : [itemDetails.playBefore];
            for (let i = 0; i < pbfs.length; i++) {
                var pbf = pbfs[i];
                if (pbf.beforeDelay) {
                    await wait(pbf.beforeDelay)
                }
                await soundManager.playSound("instruction", pbf.audio)
                if (pbf.afterDelay) {
                    await wait(pbf.afterDelay)
                }
            }
        }
        if (stageInfo.delayBefore) {
            await wait(stageInfo.delayBefore)
        } else {
            await wait(1000)
        }


        if (stageInfo.playBefore) {
            var item = lessonDetails.data[current]
            if (item.sequential) {
                await soundManager.playSequential("lesson", `L${lessonId}_${current}`, item.sequential)
            } else {
                if (item.ref) {
                    await soundManager.playSound("lesson", `L${lessonId}_${item.ref.toUpperCase()}`)
                } else {
                    await soundManager.playSound("lesson", `L${lessonId}_${current}`)
                }
            }

            await wait(1000)
        }
    }, [itemDetails, stageInfo, repeatId, current])
    const registerTextInput = useCallback(() => {
        return {
            async formSubmit() {
                if (textBlob.current?.length > 0) {
                    setStatus("processing")
                }

            },
            disabled: !((status === "started") && ((countDownState == "running") && (countDown > 0) && (countDown < countDownAmount))),
            setText(text) {
                textBlob.current = text
            },
            async playAgain() {
                return await playInstructionsOptionally()
            }
        }

    }, [status, current, playInstructionsOptionally, countDownState, countDown, countDownAmount])

    const [showSilentNotice, setShowSilentNotice] = useState(false)
    const submissionHandler = useCallback(async (lessonId, trialId, data) => {
        stop()
        const result = await submitLesson(lessonId, trialId,
            data,
            async (result) => {
                if (result.sentence) {
                    var sentence = Object.keys(result.sentence).reduce((prev, wrd) => ({ ...prev, [wrd.toLowerCase()]: result.sentence[wrd] }), {})
                    setSentenceResults((results) => ({ ...results, [current]: sentence }))
                } else {
                    setPhonemeResults(result.phonemes)
                }

                if (result.score === 0 && !result.isCorrect && lessonId === "4" && itemDetails?.type !== "text") {
                    setShowSilentNotice(true)
                }

                if (!stageInfo.dontShowStatus) {
                    if (result.isCorrect) {
                        setStatus("pass")
                    } else {
                        setStatus("fail")
                    }
                } else {
                    setStatus(null)
                }
            },
            {
                soundManager, async postTickPlay(result) {
                    if (result.isCorrect) {
                        if (stageInfo.correctPrompt) {
                            await soundManager.playFrom("status", stageInfo.correctPrompt)
                        }
                    } else {
                        if (data.uploadId || data.answer) {
                            if (stageInfo.wrongPrompt) {
                                await soundManager.playFrom("status", stageInfo.wrongPrompt)
                            }
                        }
                    }
                }, playProcessing: false, reminder: itemDetails.reminder,
                itemDetails: {
                    ...itemDetails,
                    ...stageInfo
                }
            });

            //bek
            isCorrected.current = result.isCorrect;
            tryAgainRef.current = result.tryAgain;
           
        await wait(500)
        if (itemDetails.playAfter && (result.next != current) && !itemDetails.reminder) {
            
            var pbfs = Array.isArray(itemDetails.playAfter) ? itemDetails.playAfter : [itemDetails.playAfter];
            for (let i = 0; i < pbfs.length; i++) {
                var pbf = pbfs[i];
                if (pbf.beforeDelay) {
                    await wait(pbf.beforeDelay)
                }
                await soundManager.playSound("instruction", pbf.audio)
                if (pbf.afterDelay) {
                    await wait(pbf.afterDelay)
                }
            }
        }

        const playReminder = itemDetails.reminder && result.next !== current && lessonId !== 1 && !result.isCorrect
        if (playReminder) {
            let url = `https://read21-assets.s3.amazonaws.com/reminder/${lessonId}/${itemDetails.reminder}.wav`
            if (lessonId == 8 || lessonId == 2 || lessonId == 4 || lessonId == 5) {
                url = `https://read21-assets.s3.amazonaws.com/reminder/${lessonId}/${itemDetails.reminder}.mp3`
            }
            await soundManager.playURL(url)
        }



        stop()
        if (result.end) {
            setStatus(null)
            setNextPage("end")
            if (revisionCb) {
                await wait(1000)
                revisionCb()
            } else {
                if (isReview) {
                    await wait(1000)
                    restartLesson()
                } else {
                    await wait(1000)
                    setIsEnded(true)

                    setNextPage(null)
                }
            }
        } else {

            setStatus(null)
            setIsOptionalGrowth(result.optionalGrow)
            if (result.isCorrect) {
                wrongCount.current = 0;
            } else {

                wrongCount.current += 1;
                const groups = ["microphone", "p5", "p3", "p4", "p5"]
                const shouldPlayNotice = result?.reason === "silent" && groups.includes(itemDetails.group)
                if (shouldPlayNotice) {
                    const url = `https://read21-assets.s3.amazonaws.com/warning/warn.mp3`
                    await soundManager.playURL(url)
                }
                if (wrongCount.current >= 5) {
                    await triggerPausing();
                    wrongCount.current = 0;
                }
            }

            if (result.tryAgain) {
                const playReminder = result.next == current &&  !result.isCorrect
                if (playReminder) {
                    let url
                    if (lessonId == 4 && itemDetails.hint) {
                        url = `https://read21-assets.s3.amazonaws.com/reminder/${lessonId}/${itemDetails.hint}.mp3`
                        await soundManager.playURL(url)
                    }else if (lessonId == 7 || lessonId == 9 || lessonId == 11 || lessonId == 12) {
                        url = `https://read21-assets.s3.us-east-1.amazonaws.com/reminder/7/wword.mp3`
                        await soundManager.playURL(url)
                    }
                }
                //check if the item is a word

                if (itemDetails.type === "word") {
                    if (itemDetails.text?.length > 1) {
                        if (lessonId !== 0) {

                            var trialOn;
                            if (lessonId < 8) {
                                trialOn = "third"
                            } else if (lessonId < 19) {
                                trialOn = "second"
                            }
                            if (trialOn === trialType) {
                                await initiatePlusTrainServo(

                                    itemDetails.text?.replace(/\<mark\>/ig, "").replace(/\<\/mark\>/ig, "").replace(/\-/ig, "")
                                )
                                await wait(2000)
                            }
                        }
                    }
                }
                setRepeatId(result.repeatId)
            } else {
               //bek
                isCorrected.current = true
                tryAgainRef.current = false
                if (result.dyslexia) {
                    navigate("/dyslexia")
                    return
                }
                setNextPage(lessonDetails.data[result.next].group)
                if (lessonDetails.data[result.next].group !== stage) {
                    if (stageInfo.playAfter) {
                        await soundManager.playSound("instruction", stageInfo.playAfter.audio)
                        if (stageInfo.playAfter.afterDelay) {
                            await wait(stageInfo.playAfter.afterDelay)
                        }
                    }
                }
                setNextItem(result.next)
                await wait(1000)
                await setCurrent(result.next)
            }
            if ((lessonDetails.data[result.next].group === stage)) {
                setStatus("started")
            }
        }
    }, [stageInfo, setCurrent, itemDetails, trialType, lessonDetails, stop, stage, isReview, trialId, revisionCb])
    const practiceSubmissionHandler = useCallback(async (lessonId, key, data) => {
        stop()
        const result = await submitLessonPractice(lessonId, key,
            data,
            async (result) => {
                if (result.sentence) {
                    var sentence = Object.keys(result.sentence).reduce((prev, wrd) => ({ ...prev, [wrd.toLowerCase()]: result.sentence[wrd] }), {})
                    setSentenceResults((results) => ({ ...results, [current]: sentence }))
                }

                if (result.isCorrect) {
                    setStatus("pass")
                } else {
                    setStatus("fail")
                }
            },
            { soundManager, playProcessing: false })
        await wait(500)
        setStatus(null)
        await wait(1000)
        setStatus("started")

    }, [])
    const initiateQuestions = useCallback(() => {
        setStatus("started")
    }, [])

    const registerPractice = useCallback((lessonId, countDown, type, isLarge = false) => {
        if (isLarge) {
            setShowsStatus(false)
        }
        setPracticeDetails({ lessonId: lessonId, countDown: countDown, type })

        setIsPractice(true)
        return {
            onClick: (iKey) => {
                setPracticeDetails({ lessonId: lessonId, countDown: countDown, current: iKey, type })
                return practiceMicrophoneOperation(lessonId, iKey)
            },
            micEnabled: status === "started",
            status
        }
    }, [status, practiceMicrophoneOperation])

    useEffect(() => {

        if (silenceBuzzing) {
            (async () => {

                setTimeout(async () => {
                    await soundManager.cleanChannel("attn")
                    setSilenceBuzzing(false)
                }, 15000);
                await soundManager.playSound("attn", "bell", true, 0.8)

            })()
        }
    }, [silenceBuzzing]);

    useEffect(() => {
        audioBlob.current = null;
        textBlob.current = null;
        setHasRecorded(false)
        setShowsStatus(true)
        silences.current = 0;
        setPhonemeResults(null)
        /**
         *
         * @type {HTMLInputElement}
         */
        var clearableInput = document.getElementById("clearableInputText")
        if (clearableInput) {
            clearableInput.value = "";
        }

        //setStatus(null)
    }, [current, trialId])

    useEffect(() => {
        setNextPage(null)
        setNextItem(null)
        setIsEnded(false)
        setIsPractice(false)
        setStatus(null)
    }, [trialId])

    useEffect(() => {
        if (isPractice) {
            const { current } = practiceDetails
            if (current && (countDownState == "complete") && (countDownId == current)) {
                if (hasRecorded) {
                    stop()
                } else {
                    practiceSubmissionHandler(getApplicableLessonID(lessonId), current, { uploadId: null, answer: textBlob.current })
                }
            }
        } else {
            if (current && (countDownState == "complete") && (countDownId == current)) {
                if (hasRecorded) {
                    stop()
                } else {
                    submissionHandler(lessonId, trialId, { uploadId: null, answer: textBlob.current })
                }
            }
        }

    }, [current, countDownState, countDownId, hasRecorded, practiceDetails, isPractice])
    useEffect(() => {

        if (status == "processing") {
            stop()
        }
        if (isPractice) {
            (async () => {
                const { current, type } = practiceDetails
                if (status === "started") {
                    if (practiceDetails?.countDown) {
                        reset(false, practiceDetails?.countDown, current)
                    }
                } else if (status === "processing") {
                    if (type === "word") {
                        const { url, uploadId } = s3FieldsRef.current
                        var isUploaded = false;
                        soundManager.playSound("processing", "processing", true, 0.06)
                        if (audioBlob.current) {
                            await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav")
                            isUploaded = true;
                        }

                        await practiceSubmissionHandler(lessonId, current, { uploadId: isUploaded ? uploadId : null })
                    } else if (type === "sentence") {
                        const { url, uploadId } = s3FieldsRef.current
                        var isUploaded = false;
                        soundManager.playSound("processing", "processing", true, 0.06)
                        if (audioBlob.current) {
                            await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav")
                            isUploaded = true;
                        }

                        await practiceSubmissionHandler(lessonId, current, { uploadId: isUploaded ? uploadId : null })
                    } else if (type === "text") {
                        await practiceSubmissionHandler(lessonId, current, { answer: textBlob.current })
                    }



                }

            })().catch(console.error)

        }
        else {
            if (itemDetails && stageInfo) {
                (async () => {
                    if (status === "started") {
                        console.debug("StageInfo= ", stageInfo)
                        await playInstructionsOptionally()

                    }

                    if ((itemDetails.type === "word")) {

                        if ((status === "started")) {

                            if (((!stageInfo.microphone) && (!itemDetails.microphone)) || (stageInfo.startImmediately)) {
                                if (stageInfo.countDown) {
                                    reset(false, (itemDetails.timeOut || stageInfo.timeOut || 20), current)
                                }
                                await microphoneOperation()
                            } else {
                                reset(false, (itemDetails.timeOut || stageInfo.timeOut || 20), current)
                            }


                        }
                        else if (status === "processing") {
                            const { url, uploadId } = s3FieldsRef.current
                            var isUploaded = false;
                            soundManager.playSound("processing", "processing", true, 0.06);
                            if (audioBlob.current) {
                                await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav");
                                isUploaded = true;
                            }


                            await submissionHandler(lessonId, trialId, { uploadId: isUploaded ? uploadId : null });



                        }

                    }
                    else if (itemDetails.type === "sentence") {
                        if ((status === "started")) {

                            if ((!stageInfo.microphone) && (!itemDetails.microphone)) {
                                await microphoneOperation()
                            } else {
                                reset(false, (itemDetails.timeOut || stageInfo.timeOut || 20), current)
                            }

                        } else if (status === "processing") {
                            const { url, uploadId } = s3FieldsRef.current
                            var isUploaded = false;
                            soundManager.playSound("processing", "processing", true, 0.06)
                            if (audioBlob.current) {
                                await uploadToS3(url, audioBlob.current, "audio.wav", "audio/x-wav")
                                isUploaded = true;
                            }
                            const dur = startTime.current ? (new Date() - startTime.current) : 0
                            await submissionHandler(lessonId, trialId, { uploadId: isUploaded ? uploadId : null, meta: metaBlob.current, wcpm: metaBlob.current?.wcpm, duration: dur / 1000 })
                        }
                    } else if (itemDetails.type === "text") {

                        if (status === "started") {
                            if (!((itemDetails.timeOut || stageInfo.timeOut) === -1)) {
                                if (stageInfo.subType === "letterSound") {
                                    const key = itemDetails.key.split("")[1].toLowerCase()
                                    await soundManager.playImmediate(`p1/part1-${key}`)
                                }
                                reset(false, (itemDetails.timeOut || stageInfo.timeOut || 20), current)
                                await soundManager.playImmediate("recording")

                            }

                        } if (status === "processing") {
                            soundManager.playSound("processing", "processing", true, 0.06)
                            await submissionHandler(lessonId, trialId, { answer: textBlob.current })
                        }
                    }
                })()
            }
        }



    }, [current, itemDetails, status, stageInfo, practiceDetails]);






    const stopPresentable = useMemo(() => status && (status !== "started"), [status])
    return {
        showsStatus,
        status, nextItem, nextPage, repeatId, silenceBuzzing, stopPresentable, isEnded,
        phonemeResults,
        showSilentNotice,
        //bek
        isCorrected,
        tryAgainRef,
        setShowSilentNotice,
        controls: {
            initiateQuestions,
            registerMicrophone,
            registerTextInput,
            sentenceResults,
            registerPractice,
            optionalGrowth,
            goToResults() {
                setStatus(null)
                setIsEnded(true)

                setNextPage(null)
            }
        }
    }
}

var retrivePausedFunction, pauseTimeOut;

function useComputer({
    lessonSetup,
    _lessonId,
    setLessonSetup
}) {

    const [revisionDetails, _setRevisionDetails] = useState(null)
    const [lessonRequirements, setLessonRequirements] = useState(null)
    const { user } = useContext(UserContext)
    /**
     * This is used to get the lesson details from 
     * the json files
     */
    const realLessonDetails = useMemo(() => {
        if (lessonSetup) {
            /**
             * this will the lesson setup from 
             * 'lesson.js' file
             */
            const lesson = Lessons[lessonSetup.lessonId]
            /**
             * this will the audio references 
             */
            var audioRefs = getAudioList(lessonSetup.lessonId)
            /**
             * this is where the actual lesson data 
             * is stored found in 'lesson.json' file
             */
            var lessonItems = lessonDatas[lesson.data].data
            /**
             * this is only used for tanning 
             * teachers.
             */
            const trainingLessonIds = ["3", "4"]
            if (user?.userType === "trainee" && trainingLessonIds.includes(lessonSetup.lessonId)) {
                lessonItems = trainingLessons[lesson.data].data
            }
            return { ...lesson, audioRefs, data: lessonItems, stages: lesson.stages }
        }

    }, [lessonSetup, _lessonId])
    const lessonDetails = useMemo(() => {
        if (revisionDetails) {
            const lesson = Lessons[revisionDetails.lessonId]

            var audioRefs = getAudioList(revisionDetails.lessonId)
            var lessonItems = lessonDatas[Lessons[revisionDetails.lessonId].data].data
            return {
                ...lesson, audioRefs, data: lessonItems, stages: Object.keys(lesson.stages).reduce((prev, skey) => {
                    var stage = lesson.stages[skey]
                    return {
                        ...prev,
                        [skey]: {
                            ...stage,
                            title: LessonTitles[revisionDetails.lessonId].title,
                            introduction: {
                                title: LessonTitles[revisionDetails.lessonId].title,
                                subTitle: "(Review)"
                            }
                        }

                    }
                }, {})
            }
        } else {

            if (lessonSetup) {
                const lesson = Lessons[lessonSetup.lessonId]

                var audioRefs = getAudioList(lessonSetup.lessonId)
                var lessonItems = lessonDatas[lesson.data].data
                return { ...lesson, audioRefs, data: lessonItems, stages: lesson.stages }
            }
        }


    }, [lessonSetup, _lessonId, revisionDetails])
    const { isReview, lessonId } = useMemo(() => revisionDetails ? revisionDetails : (lessonSetup ? ({ lessonId: lessonSetup.lessonId, extras: lessonSetup.extras, isReview: lessonSetup.extras }) : ({})), [lessonSetup, revisionDetails]);

    const [pageDetails, setPageDetails] = useState({ page: "loading", stage: null })

    useLayoutEffect(() => setPageDetails({ page: "loading", stage: null }), [_lessonId])

    const { stage, page } = useMemo(() => pageDetails, [pageDetails])

    const setPage = useCallback((value) => setPageDetails(pgd => ({ ...pgd, page: value })), [setPageDetails])

    const setStage = useCallback((value) => setPageDetails(pgd => ({ ...pgd, stage: value })), [setPageDetails])

    const [state, setState] = useState(null);
    const [coinBucketsData, setCoinBucketsData] = useState({ coinBuckets: [] })
    const [isPaused, setIsPaused] = useState(false);

    const triggerPausing = useCallback(async () => {
        await playSound('click')
        setIsPaused(true);
        try {
            retrivePausedFunction = await delayAll();
        } catch (e) {
            console.error(e)
        }
        pauseTimeOut = setTimeout(async () => {
            localStorage.removeItem("token");
            window.location.reload()
        }, 60 * 1000 * 3)
    }, [setIsPaused]);

    const [loadedInfo, _setLoadedInfo] = useState({});
    const setLoadedInfo = (d) => {
        //alert(JSON.stringify(d))
        _setLoadedInfo(d)
    }
    const setRevisionDetails = (d) => {
        //alert(JSON.stringify(d))
        _setRevisionDetails(d)
    }
    const { current: _current, trialType, trialId, notFirstPlay, revision = null } =
        useMemo(() => {
            return (revisionDetails || loadedInfo || {})
        }, [loadedInfo, revisionDetails])
    const [current, _setCurrent] = useState();


    const { isPlaying } = useContext(InstructionContext)

    const { countDown, state: countDownState, reset, start, stop, countDownId, countDownAmount } = useCountDown()

    const setCurrent = useCallback(async (value) => {
        //Play playBefore here
        if (value) {
            var dataItem = lessonDetails.data[value]


            setStage(dataItem.group)
        } else {
            setStage(null)
        }
        _setCurrent(value)


    }, [lessonDetails, _setCurrent])



    const setCurrentWRlDta = useCallback((value) => {
        //Play playBefore here
        if (value) {
            var dataItem = realLessonDetails.data[value]


            setStage(dataItem.group)
        } else {
            setStage(null)
        }
        _setCurrent(() => value)


    }, [_setCurrent, realLessonDetails])




    const isLoading = useMemo(() => page === "loading", [page])

    const startLessonCb = useCallback((lessonId, extras) => {
        window.soundManager.cleanAllChannel()
        setLoadedInfo({})
        setPageDetails({ page: "loading", stage: null })
        startLesson(lessonId, extras).then(res => {
            if (!res.data.otherRequirements) {
                getCoins(res.data.trialId).then(coins => {
                    setCoinBucketsData(coins)
                    setLoadedInfo({ ...res.data });

                    if ((res.data.notFirstPlay) || (res.data.isExtra) || (lessonDetails.noIntroduction)) {
                        (async () => {
                            await _setCurrent(res.data.current)
                            setPageDetails({ page: "lesson", stage: lessonDetails.data[res.data.current].group })

                        })()

                    } else {
                        setPageDetails({ page: "introduction", stage: lessonDetails.data[res.data.current].group })
                    }

                })
            } else {
                setPage("requirements")
                setLessonRequirements(res.data.otherRequirements)
                //window.switchToErrorPage("")
                //setPlayDetails({...res.data,})
            }


        })
    }, [lessonDetails]);

    const restartLesson = useCallback((lId = _lessonId, extras = null) => {
        console.log({ _lessonId })
        setLessonSetup({ lessonId: lId, extras })
    }, [_lessonId, setLessonSetup, startLessonCb])
    const revisionCb = useMemo(() => revisionDetails ? () => {
        window.soundManager.cleanAllChannel()
        setCurrentWRlDta(loadedInfo.current)

        setRevisionDetails(() => null)

    } : null, [revisionDetails, loadedInfo, setCurrentWRlDta])
    const validityResult = useValidity({
        lessonId,
        trialType,
        trialId,
        revisionCb,
        triggerPausing,
        isPlaying,
        lessonDetails,
        current,
        isPaused,
        countDown,
        countDownAmount,
        countDownState,
        setCurrent,
        stage,
        reset,
        stop,
        countDownId,
        restartLesson,
        isReview
    })

    useEffect(() => {
        watchLessonSubmission((updatedBucket, isCoinEarned, coinGroupId) => {
            setCoinBucketsData({ coinBuckets: updatedBucket, isCoinEarned, coinGroupId })
        })
    }, [])
    useEffect(() => {
        if (lessonSetup?.lessonId) {
            startLessonCb(lessonSetup.lessonId, lessonSetup.extras)

        }

    }, [lessonSetup])


    useLayoutEffect(() => {
        if (validityResult.isEnded) {
            window.soundManager.cleanAllChannel()
            lessonResult(lessonId, trialId).then(({ data: result }) => {
                if (user?.userType === "trainee") {
                    result.wasSuccessful = true
                }
                setState(result)
                if (lessonId == 0) {
                    pauseAll()
                    activateStudent().then(result => {
                        window.location.reload()
                    })
                } else {
                    if ((result.wasSuccessful) || (lessonId == 21)) {
                        setPage('result')
                    } else {
                        setPage('review')
                    }
                }

            })
        }
    }, [validityResult.isEnded])



    useLayoutEffect(() => {
        if (lessonDetails) {
            soundManager.updateAudioRefs(lessonDetails.audioRefs)
        }

        if (revisionDetails) {
            (async () => {
                await _setCurrent(revisionDetails.current)
                setPageDetails({ page: "lesson", stage: lessonDetails.data[revisionDetails.current].group })
            })().catch(alert)

        }
    }, [revisionDetails, lessonDetails])
    const goToNextPage = useCallback(() => {
        if (page === "introduction") {
            if (revision) {
                setRevisionDetails(revision)
            } else {
                setPage("begin")
            }
            //
        } else if (page === "begin") {
            (async () => {
                await _setCurrent(_current)
                setPageDetails({ page: "lesson", stage: lessonDetails.data[_current].group })

            })()
        }

    }, [page, current, _current, lessonDetails, revision])

    const specificData = useMemo(() => {
        if (pageDetails.page && pageDetails.stage && lessonDetails) {
            return Object.keys(lessonDetails.data).filter(k => lessonDetails.data[k].group === pageDetails.stage)
                .reduce((prev, curr) => {
                    var vl = { ...prev, [curr]: lessonDetails.data[curr] }
                    if (lessonDetails.data[curr].ref) {
                        vl[lessonDetails.data[curr].ref.toUpperCase()] = lessonDetails.data[lessonDetails.data[curr].ref.toUpperCase()]
                    }
                    return vl
                }, {})
        }

    }, [pageDetails, lessonDetails])



    return {
        page,
        stage,
        specificData,
        state,
        current,
        isPlaying,
        countDown,
        isPaused,
        setIsPaused,
        triggerPausing,
        countDownState,
        goToNextPage
        , ...validityResult,
        coinBucketsData,
        restartLesson,
        trialId,
        trialType,
        notFirstPlay,
        lessonDetails,
        isRevision: revisionDetails ? true : false,
        lessonRequirements,
    }
}


var proceduralInterval = null;


export default function LessonPlay({ }) {
    const { lessonId: _lessonId } = useParams();

    const [lessonSetup, _setLessonSetup] = useState(null)
    const setLessonSetup = useCallback((value) => {
        soundManager.cleanAllChannel();
        _setLessonSetup(value)
    }, [])
    const [closeModalOpen, setIsCloseModalOpen] = useState(false)
    const [modal, setModal] = useState(null)
    const [pageTransitioning, setPageIsTransitioning] = useState(false)
    const firstUpdate = useRef(true);

    useLayoutEffect(() => setLessonSetup({ lessonId: _lessonId, extras: null }), [_lessonId])
    const { shownButton } = useContext(StatusContext)

    const navigate = useNavigate();
    //const {isLoading, coinBucketsData, restartLesson, ...playDetails} = useInitializer({lessonId, _lessonId, extras})
    const computerResults = useComputer({ lessonSetup, setLessonSetup, _lessonId })
  
    const {
        page: __page,
        stage,
        state,
        notFirstPlay,
        lessonDetails,
        isRevision,
        status,
        current,
        restartLesson,
        lessonRequirements,
        controls,
        countDown,
        countDownState,
        isPaused,
        setIsPaused,
        triggerPausing,
        isPlaying,
        silenceBuzzing,
        stopPresentable,
        specificData,

        //bek
        isCorrected,
        tryAgainRef,
        isEnded,
        repeatId,
        nextItem,
        nextPage,
        goToNextPage,
        showsStatus,
        coinBucketsData,
        trialId,
        trialType,
        phonemeResults,
        showSilentNotice,
        setShowSilentNotice
    } = computerResults



    const [page, setPage] = useState(() => __page)
    // useMemo(()=>{
    //     console.log("Current / Specific Data / Trial Id", current, specificData?.[current], trialId)
    //     console.log("Stage", stage)
    //     console.log("Lesson Details", lessonDetails?.data[current])
    //     return true
    // }, [current, specificData, trialId, stage, lessonDetails, page])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setPageIsTransitioning(__page)
        setTimeout(() => {
            setPage(__page)
            setPageIsTransitioning(null)
        }, 1000)
    }, [__page])

    const currentStage = useMemo(() => (stage && (page == "lesson")) ? lessonDetails.stages[stage] : null, [lessonDetails, stage, page]);

    const CurrentLayout = useMemo(() => (stage && (page == "lesson")) ? require((`../components/stages/${lessonDetails.stages[stage].type}`)).default : null, [lessonDetails, stage, page]);

    const windowSize = useWindowSize();

    const { lessonId, extras } = useMemo(() => lessonSetup || {}, [lessonSetup])
    useEffect(() => {
        clearInterval(proceduralInterval)
        if (lessonId) {
            proceduralInterval = setInterval(() => {
                pingLesson(lessonId)
            }, 5000)
        }
        return () => {
            clearInterval(proceduralInterval)
        }
    }, [lessonId])

    useEffect(() => {

        return initiateVolumeMeter()
    }, [])

    // useEffect(() => {
    //     if (page !== "review") {
    //         setPage("review")
    //     }
    // }, [page])

    useEffect(() => {
        if (showSilentNotice) {
            soundManager.playURL("https://read21-assets.s3.amazonaws.com/fl/mod/3/notify2.mp3").then(res => {
                setShowSilentNotice(false)
            })
        }
    }, [showSilentNotice])

    if (!lessonId) {
        return <div></div>
    }




    return <div className={styles.container}>

        <head>
            {
                (lessonId == 0) ? <title>Setting up Read21!</title> : <title>L{lessonId} - {LessonTitles[lessonId].title}</title>
            }

        </head>
        {
            /**
             * Left side info of lesson
             * then Coins list with Home Button
             * then MicroPhone popup on the right top
             */
        }
        <div className={styles.lessonInformation}>
            {
                (lessonId == 0) ? <span>Getting Started with Read21!</span>
                    :
                    <span><b>L{lessonId}</b>&nbsp; &nbsp; {LessonTitles[lessonId].title}</span>
            }
        </div>
        {
            modal && <Modal close={() => {
                retrivePausedFunction?.()
                setModal(null)
            }}>
                {
                    modal?.type == "video" && <video autoPlay={true} autoBuffer={true} controls={true} controlsList={"nodownload"} src={modal.content} className={styles.videoPresentation} onEnded={() => {
                        retrivePausedFunction?.()
                        setModal(null)
                    }
                    } />
                }
            </Modal>
        }

        {
            showSilentNotice && <Modal close={() => {
                setShowSilentNotice(false)
            }}>
                <div className='bg-white p-4 rounded-md flex flex-col items-center gap-4'>
                    <img
                        src='./speak-loud.jpeg'
                        className='w-60 h-60'
                    />
                    <p className='text-center text-xl font-bold'>
                        Please speak loudly and clearly.
                    </p>
                </div>
            </Modal>
        }



        {
            closeModalOpen && <Modal close={() => {
                retrivePausedFunction?.()
                setIsCloseModalOpen(false)
            }}>
                <div style={{ display: "flex", padding: "20px 20px", borderRadius: 20, background: "#fff", flexDirection: "row", gap: 15 }}>
                    <Button direction={"left"} icon={"home"} background={"blue"} title={"Home"} onClick={() => {
                        window.location.reload()
                    }
                    } />
                    <Button direction={"left"} icon={"logout"} background={"red"} title={"Logout"} onClick={() => {
                        localStorage.removeItem("token");
                        window.location.reload()
                    }
                    } />
                </div>

            </Modal>
        }

        {
            ((page == "lesson") && (lessonId != 0)) && <div className={styles.coinsAndButtons}>
                {
                    (!extras && !isRevision) ? <CoinsContainer {...coinBucketsData} /> : <div style={{ flex: 1 }} />
                }

                {
                    ((windowSize.width > 505) && (page == "lesson")) && <button disabled={isPlaying} onClick={async () => {
                        setIsCloseModalOpen(true)
                        await playSound("click")
                        try {
                            retrivePausedFunction = await delayAll();
                        } catch (e) {
                            console.error(e)
                        }

                    }} className={clsx(styles.homeButton, isPlaying && styles.isButtonDisabled)}>
                        <svg viewBox="0 0 35 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.2991 8.21328C16.2991 7.44074 15.9922 6.69985 15.446 6.15358C14.8997 5.60732 14.1588 5.30043 13.3863 5.30043C12.6138 5.30043 11.8729 5.60732 11.3266 6.15358C10.7803 6.69985 10.4734 7.44074 10.4734 8.21328V22.7775L7.61884 19.9229C7.04703 19.3518 6.30077 18.9881 5.49859 18.8895C4.69642 18.791 3.88433 18.9634 3.19131 19.3792C2.54067 19.7693 2.06788 20.3978 1.8735 21.1311C1.67912 21.8644 1.77848 22.6446 2.15045 23.3057C5.79928 29.7917 7.92566 33.4988 8.53153 34.4289C8.65791 34.6235 8.78478 34.8176 8.91215 35.0115C9.97343 36.623 11.4183 37.9458 13.1171 38.8609C14.8159 39.7761 16.7154 40.255 18.645 40.2546H18.241H22.1248C25.215 40.2546 28.1786 39.0271 30.3636 36.842C32.5487 34.657 33.7762 31.6934 33.7762 28.6032V12.0971C33.7762 11.3245 33.4694 10.5836 32.9231 10.0374C32.3768 9.49112 31.6359 9.18423 30.8634 9.18423C30.0909 9.18423 29.35 9.49112 28.8037 10.0374C28.2574 10.5836 27.9505 11.3245 27.9505 12.0971M16.2991 8.21328V20.8356M16.2991 8.21328V4.32948C16.2991 3.94696 16.3745 3.56818 16.5209 3.21478C16.6672 2.86137 16.8818 2.54026 17.1523 2.26978C17.4228 1.9993 17.7439 1.78474 18.0973 1.63835C18.4507 1.49197 18.8295 1.41663 19.212 1.41663C19.5945 1.41663 19.9733 1.49197 20.3267 1.63835C20.6801 1.78474 21.0012 1.9993 21.2717 2.26978C21.5422 2.54026 21.7567 2.86137 21.9031 3.21478C22.0495 3.56818 22.1248 3.94696 22.1248 4.32948V20.8356M22.1248 8.21328C22.1248 7.44074 22.4317 6.69985 22.978 6.15358C23.5243 5.60732 24.2652 5.30043 25.0377 5.30043C25.8102 5.30043 26.5511 5.60732 27.0974 6.15358C27.6437 6.69985 27.9505 7.44074 27.9505 8.21328V20.8356" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>



                    </button>
                }

            </div>
        }

        {
            (!isPaused && ((((lessonId != 1) || (page == "review")) && showsStatus) || (countDownState === "running"))) &&
            <div className={clsx(styles.actionContainer, ((status && (status != "started")) || (countDownState === "running")) && styles.active, status && styles[status], (countDownState === "running") && styles.countingDown)}>
                <div className={styles.contentContainer}>
                    {
                        status == "recording" && <RecordIcon />
                    }
                    {
                        status == "processing" && <Lottie
                            loop={true}
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: processingAnimation,
                            }}
                            width={(windowSize.width > 900) ? 80 : 60}
                            height={(windowSize.width > 900) ? 80 : 60}
                        />
                    }
                    {
                        status == "pass" && <CorrectIcon />
                    }
                    {
                        status == "fail" && <WrongIcon />
                    }
                    {
                        ((countDownState == "running")) && <div className={styles.countDownContainer}>
                            <svg width={20} height={20} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M77 5L95 19.4M23 5L5 19.4M80.6 87.8L87.8 95M19.4 87.8L12.2 95M51.8 41V59L41 66.2M87.8 57.2C87.8 78.0764 70.8764 95 50 95C29.1236 95 12.2 78.0764 12.2 57.2C12.2 36.3236 29.1236 19.4 50 19.4C70.8764 19.4 87.8 36.3236 87.8 57.2Z"
                                    stroke="white"
                                    stroke-width="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <h1><FlipNumbers height={35} width={24} numberStyles={styles.numberStyles} color="white" play numbers={(countDown > 60) ? formatTime(countDown) : countDown.toString()} /></h1>
                        </div>
                    }
                </div>

            </div>
        }
        <div className={clsx(styles.lessonContentContainer, pageTransitioning && styles.quitting)}>
            {
                page == "requirements" && <div style={{ display: "flex", flexDirection: "column", gap: 10, alignItems: "center", justifyContent: "center", flex: 1, alignSelf: "stretch" }}>
                    <span>Pass the following before proceeding..</span>
                    {
                        (lessonRequirements.indexOf(12) !== -1) && <Button withoutSound={true} onClick={async () => {
                            setLessonSetup({ lessonId: 12 })
                        }} icon={"nextarrow"} border={"black"} background={"blue"} title={`L12 (Syllable Breakdown)`} />
                    }
                    {
                        (lessonRequirements.indexOf(17) !== -1) && <Button withoutSound={true} onClick={async () => {
                            setLessonSetup({ lessonId: 17 })
                        }} icon={"nextarrow"} border={"black"} background={"blue"} title={`L17 (Most Common Irregular Words)`} />
                    }
                    {
                        (lessonRequirements.indexOf(18) !== -1) && <Button withoutSound={true} onClick={async () => {
                            setLessonSetup({ lessonId: 18 })
                        }} icon={"nextarrow"} border={"black"} background={"blue"} title={`L18 (Commonly Mispronounced Sounds)`} />
                    }
                </div>
            }
            {page == "loading" && <Loading />}
            {page == 'introduction' && (
                <LessonIntroduction
                    lessonId={lessonId}
                    onNext={() => {
                        goToNextPage()
                    }}
                    current={current}
                    trialId={trialId}
                    notFirstPlay={notFirstPlay}
                    silenceBuzzing={silenceBuzzing}
                    soundManager={soundManager}
                />
            )}
            {page == 'begin' && (
                <LetsBegin
                    soundManager={soundManager}
                    onNext={() => {
                        goToNextPage()
                    }}
                />
            )}
            {page == 'lesson' && CurrentLayout &&
                <CurrentLayout lessonData={lessonDetails.data} key={stage} currentPage={stage} nextPage={nextPage} nextItem={nextItem} windowSize={windowSize} stageDetails={lessonDetails.stages[stage]} controls={controls}
                    status={status} current={current} repeatId={repeatId}
                    countDown={countDown}

                    //bek
                    trialType={trialType}
                    isCorrected={isCorrected}
                    tryAgainRef={tryAgainRef}
                    lessonId={_lessonId}

                    data={specificData}
                    silenceBuzzing={silenceBuzzing}
                    stopPresentable={stopPresentable}
                    trialId={trialId}
                    phonemeResults={phonemeResults}
                />}
            {page == 'result' && (
                <ResultPage
                    lessonId={lessonId}
                    {...state}
                    restart={() => {
                        restartLesson()
                    }}
                    homePage={() => {
                        navigate("/")
                    }}
                />
            )
            }
            {
                page == "review" && (
                    <ReviewPage
                        lessonDetails={lessonDetails}
                        computerResults={computerResults}
                        lessonId={Number.parseInt(lessonId)}
                        trialId={trialId}
                        trialType={trialType}
                        {...state}
                        openExtras={(lessonId, groups) => {
                            setLessonSetup({ lessonId, extras: groups })
                        }}
                        onNext={() => {
                            soundManager.cleanAllChannel()
                            restartLesson()
                        }}
                    />
                )
            }

        </div>
        {
            ((page === 'introduction') || (page === 'lesson')) &&
            <div className={styles.rightButtonsContainer}>
                {
                    shownButton && <><Button {...shownButton} animation={"beat"} />
                    </>
                }
                {
                    currentStage?.studyButton && <><Button onClick={async () => {
                        await playSound('click')
                        try {
                            retrivePausedFunction = await delayAll();
                        } catch (e) {
                            console.error(e)
                        }
                        setModal(currentStage?.studyButton)
                    }} icon={"repeat"} border={"black"} background={"blue"} title={currentStage?.studyButton.title} />
                        <div style={{ flex: 1 }} />
                    </>
                }
                {
                    extras && <Button noSoundCheck={true} onClick={async () => {

                        restartLesson()
                    }} paddingV="full"
                        paddingH="full"
                        title={`Go!`}
                        icon={"right"}
                        background={"green"}
                        click={"inside"} />
                }
                <InstructionMark itemDetails={
                    lessonDetails?.data?.[current]
                } />
                <button onClick={async () => {
                    await triggerPausing()
                }} disabled={isPlaying} className={clsx(styles.pauseButton, isPlaying && styles.isButtonDisabled)}>
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 0H2C1.46957 0 0.96086 0.210714 0.585787 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585787 19.4142C0.96086 19.7893 1.46957 20 2 20H4C4.53043 20 5.03914 19.7893 5.41421 19.4142C5.78929 19.0391 6 18.5304 6 18V2C6 1.46957 5.78929 0.960859 5.41421 0.585786C5.03914 0.210714 4.53043 0 4 0V0ZM14 0H12C11.4696 0 10.9609 0.210714 10.5858 0.585786C10.2107 0.960859 10 1.46957 10 2V18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0V0Z"
                            fill="white" />
                    </svg>

                </button>
            </div>

        }

        {
            isPaused && <div className={styles.pausedPage}>
                <h1>Paused</h1>
                <Button noSoundCheck={true} processingLoader={false} onClick={async () => {
                    clearTimeout(pauseTimeOut)
                    await retrivePausedFunction?.();
                    setIsPaused(false)
                }} icon={"nextarrow"} border={"white"} background={"green"} title={`Continue`} />
            </div>
        }
    </div>
}

