import { Icons } from "./icons";

const { shuffleArray } = require("../utils/common");

const Tables = {
    l5p1: [
        ["W1", "W2", "W3", "W4", "W5", "W6"],
        ["W7", "W8", "W9", "W10", "W11", "W12"],
        ["W13", "W14", "W15", "W16", "W17", "W18"],
        ["W19", "W20", "W21", "W22", "W23", "W24"],
    ],
    l5p2: [
        ["T1", "T2", "T3", "T4", "T5", "T6"],
        ["T7", "T8", "T9", "T10", "T11", "T12"],
        ["T13", "T14", "T15", "T16", "T17", "T18"],
        ["T19", "T20", "T21", "T22", "T23", "T24"],
    ],
    l6: [
        {
            table: [["W1", "W4", "W6"],
            ["W2", "W5", "W7"],
            ["W3", null, "W8"]
            ],
            title: "R-BLENDS"
        },
        {
            table: [["W9", "W11", "W13"],
            ["W10", "W12", "W14"]],
            title: "L-BLENDS"
        },
        {
            table: [
                ["W15", "W17", "W19", "W21"],
                ["W16", "W18", "W20", "W22"]
            ],
            title: "S-BLENDS"
        },
        {
            table: [["W23", "W27", "W29"],
            ["W24", "W28", "W30"],
            ["W25", null, "W31"],
            ["W26", null, "W32"]
            ],
            title: "Mixed-BLENDS"
        }
    ],
    l8: [
        {
            table: [
                ["W1", "W4", "W6"],
                ["W2", "W5", "W7"],
                ["W3", "W8", "W9"]
            ],
            title: "Vowel Combinations",
        },
        {
            table: [
                ["W10", "W11", "W12"],
                ["W13", "W14", "W15"],
                ["W16", "W17", "W18"]
            ],
            title: "Vowel Combinations"
        },
        {
            table: [
                ["W19", "W20", "W21"],
                ["W22", "W23", "W24"],
                ["W25", "W26", "W27"]
            ],
            title: "Vowel Combinations"
        }
    ],
    l10: [
        {
            table: [["W1", "W5", "W9"],
            ["W2", "W6", "W10"],
            ["W3", "W7", "W11"],
            ["W4", "W8", "W12"]
            ],
            title: "Vowel/Consonant Blends"
        },
        {
            table: [
                ["W13", "W16", "W19"],
                ["W14", "W17", "W20"],
                ["W15", "W18", null]
            ],
            title: "Vowel/Consonant Blends"
        },
        {
            table: [
                ["W21", "W24", "W26"],
                ["W22", "W25", "W27"],
                ["W23", null, "W28"]
            ],
            title: "Vowel/Consonant Blends"
        }
    ],
    l13: [["W1", "W9", "W16"], ["W2", "W10", "W17"], ["W3", "W11", "W18"], ["W4", "W12", "W19"], ["W5", "W13", "W20"], ["W6", "W14", "W21"], ["W7", "W15", "W22"], ["W8", null, "W23"]],
    l15: [["W1", "W11", "W21"], ["W2", "W12", "W22"], ["W3", "W13", "W23"], ["W4", "W14", "W24"], ["W5", "W15", "W25"], ["W6", "W16", "W26"], ["W7", "W17", "W27"], ["W8", "W18", "W28"], ["W9", "W19", "W29"], ["W10", "W20", "W30"]],
    l17: [
        {
            table: ["W1", "W2", "W3", "W4", "W5", "W6", "W7", "W8"],
            title: "Group 1",
        },
        {
            table: ["W9", "W10", "W11", "W12", "W13", "W14", "W15", "W16"],
            title: "Group 2",
        },
        {
            table: ["W17", "W18", "W19", "W20"],
            title: "Group 3",
        },

    ],
    l18: [
        {
            table: ["R1", "R2", "R3", "R4", "R5", "R6", "R7", "R8"],
            title: "Group 4",
        },
        {
            table: ["R9", "R10", "R11", "R12", "R13", "R14", "R15", "R16"],
            title: "Group 5",
        },
        {
            table: ["R17", "R18", "R19"],
            title: "Group 6",
        },
    ],
    l18Repeat: [
        {
            table: ["X1", "X2", "X3", "X4", "X5", "X6", "X7", "X8"],
            title: "Group 4",
        },
        {
            table: ["X9", "X10", "X11", "X12", "X13", "X14", "X15", "X16"],
            title: "Group 5",
        },
        {
            table: ["X17", "X18", "X19"],
            title: "Group 6",
        },

    ],
}


export const Lessons = {
    default: {
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/chimes/{{key}}.mp3",
                data: ["bell", "drag", "drop", "click", "coin", "correct", "failure", "notifications", "processing", "recording", "success", "wrong", "twinkle"],
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/repeatables/{{key}}.mp3",
                prefix: "repeatables/",
                data: ["mic-p", "L1", "L2", "L3-GP1", "L3-GP2", "L3-P1", "L3-P2", "L4-P1", "L4-P2", "L4-P3", "L5-P1", "L6-CB", "L6-GP", "L7-P1", "L7-P2", "L7-P3", "L8-P1", "L8-VC", "L9-P1", "L9-P2", "L9-P3", "L10-P1", "L10-VC", "L11-P1", "L11-P2", "L11-P3", "L12-P1", "L12-P2", "L13-P1", "L13-P2", "L14-P1", "L14-P2", "L14-P3", "L15-P1", "L15-P2", "L16-P1", "L16-P2", "L16-P3", "L17-P1", "L17-P2", "L18-GP", "L18-P1", "L19", "L20", "L21"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/endings/{{key}}.mp3",
                prefix: "endings/",
                data: ["avfail", "l1fail", "l1pass", "l2fail", "l2pass", "l3fail", "l3pass", "l4fail", "l4pass", "l5fail", "l5pass", "l6fail", "l6pass", "l7fail", "l7pass", "l8fail", "l8pass", "l9fail", "l9pass", "l10fail", "l10pass", "l11fail", "l11pass", "l12fail", "l12pass", "l13fail", "l13pass", "l14fail", "l14pass", "l15fail", "l15pass", "l16fail", "l16pass", "l17fail", "l17pass", "l18fail", "l18pass", "l19fail", "l19pass", "l20fail", "l20pass", "l21"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/review/{{key}}.mp3",
                prefix: "review/",
                data: ["review", "review2", "reviewlesson"]
            },
            {
                "audio": "https://read21-assets.s3.amazonaws.com/fl/mod/3/{{key}}.mp3",
                "prefix": "mod/",
                data: ["3a", "3b", "3c", "3d", "3e", "3f", "3g"]
            },
            {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/misc/{{key}}.mp3",
                data: ["ambience", "enticing", "plustrain"],
            },

            ],
            wrong: {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/wrong/{{key}}.mp3",
                data: [1, 2, 3, 4],
                flow: "random"
            },
            empty: {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/empty/{{key}}.mp3",
                data: [1, 2, 3, 4],
                flow: "random"
            },
            correct: {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/correct/{{key}}.mp3",
                data: [1, 2, 3, 4, 5, 6, 7],
                flow: "random"
            },
            introduction: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/introduction.mp3",
            example: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/example.mp3",
            explanation: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/explanation.mp3",
            letsbegin: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/letsbegin.wav",
            tooloud: "https://d3vx0tne0vxeo1.cloudfront.net/audios/prompts/tooloud.mp3",

        }
    },
    0: {
        stages: {
            microphone: {
                type: "DisplayItems",
                subType: "largeWord",
                playBefore: true,
                microphone: true,
                micDisabled: true,
                startImmediately: true,
                silenceBuzz: true,
                delayBefore: 2500,
                steps: [
                    {
                        type: "audio",
                        content: "repeatables/mic-p",
                        afterDelay: 1000
                    },
                ],
                title: "Working the Mic",
                instruction: "repeatables/mic-p",
                introduction: {
                    title: "Working the Mic",
                    subTitle: "Training",
                    videoPresentation: "https://d3vx0tne0vxeo1.cloudfront.net/videos/microphone.mp4"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "letterSound",
                silenceBuzz: true,
                microphone: true,
                micDisabled: false,
                delayBefore: 3000,
                // timeOut: 500000,
                // startImmediately: true,
                countDown: false,
                steps: [
                    {
                        type: "countShowDown",
                        content: 10
                    },

                    {
                        type: "rawDisplay",
                        content: Icons.pretestIntroduction,
                        afterDelay: 500
                    },
                    {
                        type: "audio",
                        content: "prompt/ptinfo",
                        wBegin: true
                    },
                    {
                        type: "setTitle",
                        content: "Part 1"
                    },
                    {
                        type: "rawDisplay",
                        content: Icons.p1Introduction,
                    },
                    {
                        type: "audio",
                        content: "p1/introductory",
                        wBegin: true
                    }
                ],
                introduction: {
                    title: "Part 1",
                    subTitle: "Pre-Test",
                    // audio: "prompt/e-1"
                }
            },
            // P5 Changed position to P2
            p5: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                microphone: true,
                micDisabled: false,
                delayBefore: 3000,
                // startImmediately: true,
                countDown: true,
                title: "Part 2",
                introduction: {
                    title: "Part 2",
                    subTitle: "Pre-Test",
                    audio: "prompt/e-5"
                }
            },
            p2: {
                type: "InsertText",
                silenceBuzz: true,
                playBefore: true,
                timeOut: 30,
                title: "Part 3",
                steps: [
                    {
                        type: "video",
                        content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/0/intro2/[[ageGroup]].mp4"
                    },
                ],
                noItemTitle: true,
                introduction: {
                    title: "Part 3",
                    subTitle: "Pre-Test",
                    // audio: "prompt/e-2"
                }
            },
            p3: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                microphone: true,
                micDisabled: true,
                delayBefore: 3000,
                // startImmediately: true,
                countDown: true,
                title: "Part 4",
                introduction: {
                    title: "Part 4",
                    subTitle: "Pre-Test",
                    // audio: "prompt/e-3"
                },
                steps: [
                    {
                        type: "video",
                        content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/0/intro3/[[ageGroup]].mp4"
                    },
                ],
                playAfter: {
                    audio: "prompt/pass"
                },
            },
            p4: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                microphone: true,
                micDisabled: false,
                delayBefore: 3000,
                // startImmediately: true,
                countDown: true,
                title: "Part 5",
                steps: [
                    {
                        type: "video",
                        content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/0/intro4/[[ageGroup]].mp4"
                    },
                ],
                introduction: {
                    title: "Part 5",
                    subTitle: "Pre-Test",
                    // audio: "prompt/e-4"
                }
            },
            p6: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                title: "Part 6",
                introduction: {
                    title: "Part 6",
                    subTitle: "Pre-Test",
                    audio: "prompt/e-6"
                }
            },
            p7: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                title: "Part 7",
                timeOut: 50,
                microPhoneConfig: {
                    waitTime: 60000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Part 7",
                    subTitle: "Pre-Test",
                    audio: "prompt/e-7"
                }
            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e-1", "e-2", "e-3", "e-4", "e-5", "e-6", "e-7", "mic-1", "pass", "ptinfo"]
            }, {
                audio: "https://read21-assets.s3.amazonaws.com/fl/0/p1/{{key}}.mp3",
                prefix: "p1/",
                data: ["introductory", ...["y", "a", "e", "n", "p", "l", "b", "o", "d", "u", "w", "g", "j", "i", "z"].map(l => `part1-${l}`)]
            }]
        },
        data: "pretest",
        noIntroduction: true
    },
    1: {
        stages: {
            alphabets: {
                type: "Colorful",
                subType: "normal",
                playBefore: true,
                silenceBuzz: true,
                silencePrompt: {
                    base: "empty",
                    flow: "random",
                    from: [1, 2, 3, 4]
                },
                correctPrompt: {
                    base: "correct",
                    flow: "random",
                    from: [1, 2, 3, 4, 5, 6, 7]
                },
                wrongPrompt: {
                    base: "wrong",
                    flow: "random",
                    from: [1, 2, 3, 4]
                },
                instruction: "repeatables/L1"
            }
        },
        audioSets: {},
        data: "alphabets"
    },
    2: {
        stages: {
            alphabets: {
                type: "Colorful",
                subType: "half",
                playBefore: false,
                silenceBuzz: true,
                silencePrompt: {
                    base: "empty",
                    flow: "random",
                    from: [1, 2, 3, 4]
                },
                correctPrompt: {
                    base: "correct",
                    flow: "random",
                    from: [1, 2, 3, 4, 5, 6, 7]
                },
                wrongPrompt: {
                    base: "wrong",
                    flow: "random",
                    from: [1, 2, 3, 4]
                },
                instruction: "repeatables/L2"
            }
        },
        audioSets: {},
        data: "alphabets"
    },
    3: {
        stages: {
            gp1: {
                type: "DisplayItems",
                subType: "images",
                playBefore: true,
                silenceBuzz: true,
                correctPrompt: {
                    base: "correct",
                    flow: "random",
                    from: [1, 2, 3, 4, 5, 6, 7]
                },
                instruction: "repeatables/L3-GP1",
                delayBefore: 2500
            },
            p1: {
                type: "DisplayItems",
                subType: "imagesText",
                playBefore: true,
                silenceBuzz: true,
                correctPrompt: {
                    base: "correct",
                    flow: "random",
                    from: [1, 2, 3, 4, 5, 6, 7]
                },
                instruction: "repeatables/L3-P1",
                img: "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/{{key}}.svg",
                delayBefore: 1000
            },
            gp2: {
                type: "DisplayItems",
                subType: "characters",
                playBefore: true,
                dontPlayStatus: true,
                dontShowStatus: true,
                score: false,
                silenceBuzz: true,
                instruction: "repeatables/L3-GP2",
                flipped: true,
                delayBefore: 1000
            },
            p2: {
                type: "DisplayItems",
                subType: "charImages",
                micDisabled: true,
                silenceBuzz: true,
                correctPrompt: {
                    base: "correct",
                    flow: "random",
                    from: [1, 2, 3, 4, 5, 6, 7]
                },
                timeOut: 10,
                instruction: "repeatables/L3-P2",
                flipped: true,
                boxed: true,
                delayBefore: 1000,
                img: "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/{{key}}.svg",
            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["end", "goodjob", "mod_end", "n1", "n2", "n3_1", "n3", "n4_1", "n4_2", "n4_3", "n4"]
            }],
            "mod": {
                audio: "https://read21-assets.s3.amazonaws.com/fl/mod/{{lessonId}}/{{key}}.mp3",
                prefix: "mod/",
                data: ["3a"]
            }
        },
        data: "lesson3"
    },
    4: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                delayBefore: 2500,
                steps: [
                    {
                        type: "display",
                        content: "b-a-g"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep2",
                        afterDelay: 1000
                    },
                ],
                title: "Tap it Out!",
                introduction: {
                    title: "Tap it Out!",
                    audio: "prompt/prep1"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L4-P1",
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Blending Basics",
                    subTitle: "Practice 1",
                    audio: "prompt/p1"
                }
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L4-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Blending Basics",
                    subTitle: "Practice 2",
                    audio: "prompt/p2"
                }
            },
            p3: {
                type: "InsertText",
                background: {
                    type: "contentGrid",
                    content: "abcdefghijklmnopqrstuvwxyz".split("")
                },
                silenceBuzz: true,
                instruction: "repeatables/L4-P3",
                delayBefore: 1000,
                title: "Practice 3",
                introduction: {
                    title: "Blending Basics",
                    subTitle: "Practice 3",
                    audio: "prompt/p3"
                },
                inputFlavour: "preInserted"
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Read it Out!",
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["p1", "p2", "p3_1", "p3", "prep1", "prep2", "prep3", "prep4", "prep5", "prep6", "rio"]
            },
            {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/chars/{{key}}.mp3",
                prefix: "char/",
                data: ["p", "i", "t"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "lesson4"
    },
    5: {
        stages: {
            exp1: {
                type: "DisplayItems",
                subType: "word",
                table: {
                    data: Tables.l5p1,
                    type: "simple"
                },
                playBefore: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Guided Practice 1",
                instruction: "repeatables/L5-P1"
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                table: {
                    data: Tables.l5p1,
                    type: "simple"
                },
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Most Used Words",
                    subTitle: "Practice 1",
                    audio: "prompt/p1"
                },
                playAfter: {
                    audio: "prompt/awesome"
                },
                instruction: "repeatables/L5-P1",
            },
            rio1: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Read it Out",
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio1",
                    subTitle: "Part 1"
                }

            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                table: {
                    data: Tables.l5p2,
                    type: "simple"
                },
                playBefore: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Guided Practice 2",
                instruction: "repeatables/L5-P1",
                introduction: {
                    title: "Most Used Words",
                    subTitle: "Guided Practice 2",
                    audio: "prompt/gp"
                },
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                table: {
                    data: Tables.l5p2,
                    type: "simple"
                },
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Most Used Words",
                    subTitle: "Practice 2",
                    audio: "prompt/p2"
                },
                instruction: "repeatables/L5-P1",

            },
            rio2: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Read it Out",
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio2",
                    subTitle: "Part 2"
                },

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["awesome", "p1", "p2", "gp", "practice", "rio1", "rio2"]
            }]
        },
        review: {
            // fromLesson: "self",
        },
        data: "lesson5"
    },
    6: {
        stages: {
            exp: {
                type: "DisplayItems",
                subType: "word",
                playBefore: true,
                silenceBuzz: true,
                table: {
                    type: "multi",
                    data: Tables.l6
                },
                delayBefore: 1000,

                uiBeforeSteps: true,
                title: "Guided Practice",
                instruction: "repeatables/L6-GP"
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                instruction: "prompt/n5",
                delayBefore: 1000,
                title: "Consonant Blends",
                timeOut: 20,
                introduction: {
                    title: "Consonant Blends",
                    subTitle: "(Random Order)",
                    audio: "prompt/n5"
                }
            },
            p1: {
                type: "InsertText",
                background: {
                    type: "chaos",
                    content: shuffleArray(Array.from(new Array(32)).map((_, i) => `W${i + 1}`))
                },
                playBefore: true,
                silenceBuzz: true,
                instruction: "repeatables/L6-CB",
                delayBefore: 1000,
                title: "Consonant Blends",
                timeOut: 20,
                inputPrefix: "Blend",
                introduction: {
                    title: "Consonant Blends",
                    subTitle: "(Random Order)",
                    audio: "prompt/n4"
                }
            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["n1", "n2", "n3", "n4", "n5"]
            }

            ]
        },
        review: {
            // fromLesson: "self",
        },
        data: "lesson6"
    },
    7: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                delayBefore: 2500,
                microphone: true,
                timeOut: 20,
                steps: [
                    {
                        type: "display",
                        content: "tr-a-p"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-1"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-2",
                        afterDelay: 4000
                    },
                    {
                        type: "display",
                        content: "fl-i-p"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-3",
                        afterDelay: 2000
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-4",
                        afterDelay: 1000
                    },
                ],
                title: "Blending with Consonant Blends",
                introduction: {
                    title: "Consonant Blends",
                    audio: "prompt/n1"
                },
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 20,
                instruction: "repeatables/L7-P1",
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Consonant Blends",
                    subTitle: "Practice 1",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L7-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Consonant Blends",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p2-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p3: {
                type: "InsertText",
                timeOut: 45,
                background: {
                    type: "options",
                    content: [
                        [["cr", "pl", "ch"], ["sn", "tr", "fr"], ["th", "dr", "sc"]],
                        ["a", "e", "i", "o", "u"],
                        [["p", "d", "t"], ["m", "n", "b"]]
                    ]
                },
                silenceBuzz: true,
                instruction: "repeatables/L7-P3",
                delayBefore: 1000,
                title: "Practice 3",
                introduction: {
                    title: "Consonant Blends",
                    subTitle: "Practice 3",
                    audio: "prompt/p3-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p3-2"
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                timeOut: 30,
                delayBefore: 1000,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["n1", "p1-1", "p1-2", "p2-1", "p2-2", "p3-1", "p3-2", "prep-1", "prep-2", "prep-3", "prep-4", "rio"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/chars/{{key}}.mp3",
                prefix: "char/",
                data: ["g", "o", "pl", "sp", "t", "u"]
            }
            ]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "lesson7"
    },
    8: {
        stages: {
            p1: {
                type: "DisplayItems",
                subType: "word",
                table: {
                    type: "multi",
                    data: Tables.l8
                },
                playBefore: true,
                silenceBuzz: true,
                delayBefore: 1000,
                instruction: "repeatables/L8-P1",
                title: "Vowel Combinations"
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                // table: {
                //     type: "multi",
                //     data: Tables.l6
                // },
                instruction: "prompt/n5",
                delayBefore: 1000,
                title: "Vowel Combinations",
                timeOut: 20,
                introduction: {
                    title: "Vowel Combinations",
                    subTitle: "(Random Order)",
                    audio: "prompt/n5"
                }
            },
            p2: {
                type: "InsertText",
                playBefore: true,
                silenceBuzz: true,
                background: {
                    type: "chaos",
                    content: ['W14','W13','W1', 'W2', 'W4', 'W3', 'W12','W5', 'W6','W7', 'W9','W8', 'W15', 'W18', 'W27', 'W16', 'W17', 'W24', 'W25', 'W26', 'W20', 'W23', 'W22', 'W11', 'W10', 'W21', 'W19']
                },
                delayBefore: 1000,
                title: "Vowel Combinations",
                timeOut: 55,
                introduction: {
                    title: "Vowel Combinations",
                    subTitle: "(Random Order)",
                    audio: "prompt/n3"
                },
                instruction: "repeatables/L8-VC",
            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["n1", "n2", "n3", "n4", "n5"]
            }]
        },
        review: {},
        data: "lesson8"
    },
    9: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                delayBefore: 2500,
                microphone: true,
                timeOut: 15,
                steps: [
                    {
                        type: "display",
                        content: "p-ai-d"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-1"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-2",
                        afterDelay: 4000
                    },
                    {
                        type: "display",
                        content: "sp-in"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-3",
                        afterDelay: 2000
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-4",
                        afterDelay: 1000
                    },
                ],
                title: "Blending with Vowel Combinations",
                introduction: {
                    title: "Vowel Combinations",
                    subTitle: "Guided Practice",
                    audio: "prompt/n1"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 15,
                instruction: "repeatables/L9-P1",
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Vowel Combinations",
                    subTitle: "Practice 1",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L9-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Vowel Combinations",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p2-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p3: {
                type: "InsertText",
                background: {
                    type: "options",
                    content: [
                        [["d", "b", "p"], ["sw", "br", "k"], ["pl", "sp", null]],
                        ["ay", "ea", "ee", "in", "ow", "oo"],
                        [["p", "d", "t"], ["m", "n", "b"], ["k", "l", null]]
                    ]
                },
                silenceBuzz: true,
                instruction: "repeatables/L9-P3",
                delayBefore: 1000,
                title: "Practice 3",
                introduction: {
                    title: "Vowel Combinations",
                    subTitle: "Practice 3",
                    audio: "prompt/p3-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p3-2"
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["n1", "p1-1", "p1-2", "p2-1", "p2-2", "p3-1", "p3-2", "prep-1", "prep-2", "prep-3", "prep-4", "rio"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/chars/{{key}}.mp3",
                prefix: "char/",
                data: ["br", "l", "oi", "ou", "sp", "t"]
            }
            ]
        },
        review: {
            fromLesson: 8,
            stages: ["p1"]
        },
        data: "lesson9"
    },
    10: {
        stages: {
            p1: {
                type: "DisplayItems",
                subType: "word",
                table: {
                    type: "multi",
                    data: Tables.l10
                },
                playBefore: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Vowel/Consonant Blends",
                instruction: "repeatables/L10-P1",
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                // table: {
                //     type: "multi",
                //     data: Tables.l6
                // },
                instruction: "prompt/n3",
                delayBefore: 1000,
                title: "Vowel Combinations",
                timeOut: 20,
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "(Random Order)",
                    audio: "prompt/n3"
                }
            },
            p2: {
                type: "InsertText",
                playBefore: true,
                background: {
                    type: "chaos",
                    content: shuffleArray(Array.from(new Array(28)).map((_, i) => `W${i + 1}`))
                },
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Vowel/Consonant Blends",
                timeOut: 35,
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "Practice 2",
                    audio: "prompt/n2"
                },
                instruction: "repeatables/L10-VC",
            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["n1", "n2", "n3", "n4"]
            }]
        },
        review: {
            // fromLesson: "self",
        },
        data: "lesson10"
    },
    11: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                delayBefore: 2500,
                microphone: true,
                timeOut: 15,
                steps: [
                    {
                        type: "display",
                        content: "k-ept"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-1"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-2",
                        afterDelay: 4000
                    },
                    {
                        type: "display",
                        content: "sh-ook"
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-3",
                        afterDelay: 2000
                    },
                    {
                        type: "audio",
                        content: "prompt/prep-4",
                        afterDelay: 1000
                    },
                ],
                title: "Blending with Vowel/Consonant Blends",
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "Guided Practice",
                    audio: "prompt/n1"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 15,
                instruction: "repeatables/L11-P1",
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "Practice 1",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L11-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p2-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p3: {
                type: "InsertText",
                background: {
                    type: "options",
                    content: [
                        [["j", "b", "p"], ["l", "br", "s"], ["r", "sm", null]],
                        [["ail", "ight"], ["ash", "oft"], ["ush", "ell"], ["ook", "imp"], ["uck", "eal"]]
                    ],
                    focalIndex: 1
                },
                silenceBuzz: true,
                instruction: "repeatables/L11-P3",
                delayBefore: 1000,
                title: "Practice 3",
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "Practice 3",
                    audio: "prompt/p3-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p3-2"
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Read it Out!",
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["n1", "p1-1", "p1-2", "p2-1", "p2-2", "p3-1", "p3-2", "prep-1", "prep-2", "prep-3", "prep-4", "rio"]
            }, {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/chars/{{key}}.mp3",
                prefix: "char/",
                data: ["ell", "ight", "l", "sw"]
            }
            ]
        },
        review: {
            fromLesson: 10,
            stages: ["p1"]
        },
        data: "lesson11"
    },
    12: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "phonetics",
                silenceBuzz: true,
                delayBefore: 2500,
                steps: [
                    {
                        type: "stepLister",
                        content: `<b>Identify the vowels/vowel combinations. Place a dot under the vowels.</b>`,
                        step: 1
                    },
                    {
                        type: "audio",
                        content: "prompt/exp-1-1",
                        afterDelay: 1000
                    },
                    {
                        type: "stepLister",
                        content: `<b>Look between the <u>first</u> two vowels or Vowel combinations.</b>
                            <ul>
                                <li>Identify the <b>2 consonant</b> sounds are Side-by-Side, draw syllable line or
                                    separate them. <img src='https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/perform.png'/></li>
                            </ul>
                            <center><b>-</b> or <b>-</b></center>
                            <ul>
                                <li>If there's only <b>one consonant</b> sound, draw a syllable line after the first
                                    vowel. <img src='https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/human.png'/></li>
                            </ul>
                        `,
                        step: 2
                    },
                    {
                        type: "audio",
                        content: "prompt/exp-1-2",
                        afterDelay: 1000
                    },
                    {
                        type: "stepLister",
                        content:
                            `<span >3 or more active vowels:  <img
                                src='https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/complement.png'/></span>
                            <ul>
                                <li>Repeat step 3 <u>between each vowel or vowel combination.</u></li>
                            </ul>`
                        ,
                        clearPrevious: true,
                        step: 3
                    },
                    {
                        type: "audio",
                        content: "prompt/exp-1-3",
                        afterDelay: 3000
                    },
                    {
                        type: "video",
                        content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/12/demonstration.mp4"
                    },
                    {
                        type: "images",
                        content: ["https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/color_perform.png", "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/color_human.png"],
                    },
                    {
                        type: "audio",
                        content: "prompt/exp-2",
                        afterDelay: 2000
                    },
                ],
                playAfter: {
                    afterDelay: 3000,
                    audio: "prompt/exp-7"
                },
                lastStep: {
                    type: "images",
                    content: ["https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/protest.png", "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/12/mamal.png"]
                },
                title: "Vowel/Consonant Blends",
                introduction: {
                    title: "Vowel/Consonant Blends",
                    subTitle: "Guided Practice",
                    audio: "prompt/exp-1"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 15,
                instruction: "repeatables/L12-P1",
                delayBefore: 1000,
                title: "Practice 1",
                studyButton: {
                    title: "Replay video",
                    type: "video",
                    content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/12/demonstration.mp4"
                },
                introduction: {
                    title: "Decoding Multisyllabic Words",
                    subTitle: "Practice 1",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 25,
                studyButton: {
                    title: "Replay video",
                    type: "video",
                    content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/12/demonstration.mp4"
                },
                instruction: "repeatables/L12-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Decoding Multisyllabic Words",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p2-2"
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "sequential",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 40,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Read it Out!",
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["exp-1", "exp-1-1", "exp-1-2", "exp-1-3", "exp-2", "exp-3", "exp-4", "exp-5", "exp-5-1", "exp-5-2", "exp-6", "exp-7", "exp-8", "p1-1", "p1-2", "p2-1", "p2-2", "rio"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "lesson12"
    },
    13: {
        stages: {
            p1: {
                type: "DisplayItems",
                subType: "word",
                playBefore: true,
                silenceBuzz: true,
                delayBefore: 1000,
                microphone: false,
                timeOut: -1,
                title: "Practice 1",
                instruction: "repeatables/L13-P1",
                table: {
                    data: Tables.l13,
                    type: "complex"
                },
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                // table: {
                //     type: "multi",
                //     data: Tables.l6
                // },
                instruction: "prompt/n4",
                delayBefore: 1000,
                title: "Common Prefixes",
                timeOut: 20,
                introduction: {
                    title: "Common Prefixes",
                    subTitle: "(Random Order)",
                    audio: "prompt/n4"
                }
            },
            p2: {
                type: "InsertText",
                background: {
                    type: "chaos",
                    content: shuffleArray(Array.from(new Array(23)).map((_, i) => `W${i + 1}`))
                },
                playBefore: true,
                silenceBuzz: true,
                inputPrefix: "Prefix",
                instruction: "repeatables/L13-P2",
                delayBefore: 1000,
                title: "Practice 2",
                timeOut: 35,
                introduction: {
                    title: "Common Prefixes",
                    subTitle: "(Random Order)",
                    audio: "prompt/n3"
                }
            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e1", "n3", "n4"]
            }]
        },
        review: {
            // fromLesson: "self",
        },
        data: "lesson13"
    },

    14: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                delayBefore: 2500,
                microphone: true,
                timeOut: 15,
                steps: [
                    {
                        type: "display",
                        content: "pre-heat"
                    },
                    {
                        type: "audio",
                        content: "prompt/e2"
                    }
                ],
                title: "Blending with Prefixes",
                introduction: {
                    title: "Blending with Prefixes",
                    subTitle: "Guided Practice",
                    audio: "prompt/e1"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 15,
                instruction: "repeatables/L14-P1",
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Blending with Prefixes",
                    subTitle: "Practice 1",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L14-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Blending with Prefixes",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p2-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p3: {
                type: "InsertText",
                silenceBuzz: true,
                instruction: "repeatables/L14-P3",
                delayBefore: 1000,
                timeOut: 45,
                title: "Practice 3",
                inputPrefix: "Blend",
                introduction: {
                    title: "Blending with Prefixes",
                    subTitle: "Practice 3",
                    audio: "prompt/p3-1"
                },
                background: {
                    type: "options",
                    content: [
                        [["pre", "re"], ["un", "mid"], ["mis", "de"]],
                        [["part", "run"], ["start", "lead"], ["do", "way"], ["ploy", "real"]]
                    ],
                    focalIndex: 1
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p3-2"
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 45,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/chars/{{key}}.mp3",
                prefix: "char/",
                data: ["un", "hurt", "mid", "land"]
            },
            {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e1", "e2", "e3", "e4", "n1-1", "n1-2", "n1", "p1-1", "p1-2", "p2-1", "p2-2", "p3-1", "p3-2", "rio"]
            }
            ]
        },
        review: {
            // fromLesson: 13,
            // stages: ["p1"]
        },
        data: "lesson14"
    },
    15: {
        stages: {
            p1: {
                type: "DisplayItems",
                subType: "word",
                playBefore: true,
                silenceBuzz: true,
                delayBefore: 1000,
                title: "Practice 1",
                instruction: "repeatables/L15-P1",
                table: {
                    data: Tables.l15,
                    type: "complex"
                },
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                // table: {
                //     type: "multi",
                //     data: Tables.l6
                // },
                instruction: "prompt/e2",
                delayBefore: 1000,
                title: "Blending Basics",
                timeOut: 20,
                introduction: {
                    title: "Blending Basics",
                    subTitle: "(Random Order)",
                    audio: "prompt/e2"
                }
            },
            p2: {
                type: "InsertText",
                background: {
                    type: "chaos",
                    content: shuffleArray(Array.from(new Array(30)).map((_, i) => `W${i + 1}`))
                },
                playBefore: true,
                silenceBuzz: true,
                instruction: "repeatables/L15-P2",
                delayBefore: 1000,
                title: "Practice 2",
                inputPrefix: "Suffix",
                timeOut: 35,
                introduction: {
                    title: "Blending Basics",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-2"
                }
            }
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e1", "e2", "p2-1", "p2-2"]
            }]
        },
        review: {
            // fromLesson: "self",
        },
        data: "lesson15"
    },
    16: {
        stages: {
            prep: {
                type: "DisplayItems",
                subType: "word",
                silenceBuzz: true,
                delayBefore: 2500,
                microphone: true,
                timeOut: 15,
                steps: [
                    {
                        type: "display",
                        content: "heat-able"
                    },
                    {
                        type: "audio",
                        content: "prompt/e2",
                        afterDelay: 2000
                    },
                    {
                        type: "display",
                        content: "joy-ful"
                    },
                    {
                        type: "audio",
                        content: "prompt/e3",
                        afterDelay: 2000
                    },
                    {
                        type: "audio",
                        content: "prompt/e4",
                        afterDelay: 1000
                    },
                ],
                title: "Blending with Suffixes",
                introduction: {
                    title: "Blending with Suffixes",
                    subTitle: "Guided Practice",
                    audio: "prompt/e1"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                timeOut: 15,
                instruction: "repeatables/L16-P1",
                delayBefore: 1000,
                title: "Practice 1",
                introduction: {
                    title: "Blending with Suffixes",
                    subTitle: "Practice 1",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L16-P2",
                delayBefore: 1000,
                title: "Practice 2",
                introduction: {
                    title: "Blending with Suffixes",
                    subTitle: "Practice 2",
                    audio: "prompt/p2-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p2-2"
                    }
                ],
                uiBeforeSteps: true
            },
            p3: {
                type: "InsertText",
                silenceBuzz: true,
                instruction: "repeatables/L16-P3",
                delayBefore: 1000,
                title: "Practice 3",
                inputPrefix: "Blend",
                introduction: {
                    title: "Blending with Suffixes",
                    subTitle: "Practice 3",
                    audio: "prompt/p3-1"
                },
                background: {
                    type: "options",
                    content: [
                        [["fast", "hope"], ["bound"], ["joy", "gray"], ["brown", "match"]],
                        [["ful", "ly"], ["less", "ing"], ["ish", "er"]]
                    ],
                    focalIndex: 1
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p3-2"
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 20,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/chars/{{key}}.mp3",
                prefix: "char/",
                data: ["spec", "ial", "enjoy", "ment"]
            },
            {
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e1", "e2", "e3", "e4", "p1-1", "p1-2", "p2-1", "p2-2", "p3-1", "p3-2", "rio"]
            }
            ]
        },
        review: {
            fromLesson: 15,
            stages: ["p1"]
        },
        data: "lesson16"
    },
    17: {
        stages: {
            p1: {
                type: "DisplayItems",
                subType: "word",
                playBefore: true,
                silenceBuzz: true,
                instruction: "repeatables/L17-P1",
                delayBefore: 1000,
                title: "Practice 1",
                table: {
                    type: "multi",
                    data: Tables.l17,
                    isSmall: true
                },
                introduction: {
                    title: "Most Common Irregular Words",
                    subTitle: "Practice 1"
                }
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                // table: {
                //     type: "multi",
                //     data: Tables.l6
                // },
                instruction: "prompt/exp1",
                delayBefore: 1000,
                title: "Most Common Irregular Words",
                timeOut: 20,
                introduction: {
                    title: "Most Common Irregular Words",
                    subTitle: "(List 6)",
                    audio: "prompt/exp1"
                }
            },
            prep1: {
                type: "DisplayItems",
                subType: "phonetics",
                silenceBuzz: true,
                steps: [
                    {
                        type: "video",
                        content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/17/video-1.mp4"
                    },
                ],
                delayBefore: 2500,
                title: "Silent E (Guided Practice)",
                introduction: {
                    title: "Most Common Irregular Words",
                    subTitle: "Silent E (Guided Practice)",
                    audio: "prompt/d1"
                }
            },
            prep2: {
                type: "DisplayItems",
                subType: "phonetics",
                silenceBuzz: true,
                steps: [
                    {
                        type: "video",
                        content: "https://d3vx0tne0vxeo1.cloudfront.net/videos/lesson/17/video-2.mp4"
                    },
                ],
                title: "Silent E (Guided Practice)"
            },

            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L17-P2",
                delayBefore: 1000,
                title: "Blending with Silent E",
                introduction: {
                    title: "Most Common Irregular Words",
                    subTitle: "Blending with Silent E",
                    audio: "prompt/d2"
                }
            },
            rio: {
                type: "ReadItOut",
                subType: "list",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 25,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Read it Out!",
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["d1", "d2", "e1", "e2", "e3", "rio", "exp1"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep1", "prep2"]
        },
        data: "lesson17"
    },
    18: {
        stages: {
            exp1: {
                type: "DisplayItems",
                subType: "word",
                playBefore: true,
                silenceBuzz: true,
                table: {
                    type: "multi",
                    data: Tables.l18
                },
                instruction: "prompt/e2",
                delayBefore: 1000,
                title: "Most Common Irregular Words",
                timeOut: 20,
                introduction: {
                    title: "Most Common Irregular Words",
                    subTitle: "(List 6)",
                    audio: "prompt/gp1"
                }
            },
            exp2: {
                type: "DisplayItems",
                subType: "word",
                playBefore: false,
                silenceBuzz: true,
                microphone: true,
                table: {
                    type: "multi",
                    data: Tables.l18Repeat
                },
                // table: {
                //     type: "multi",
                //     data: Tables.l6
                // },
                instruction: "prompt/exp1",
                delayBefore: 1000,
                title: "Most Common Irregular Words",
                timeOut: 20,
                introduction: {
                    title: "Most Common Irregular Words",
                    subTitle: "(List 6)",
                    audio: "prompt/gp2"
                }
            },
            p1: {
                type: "DisplayItems",
                subType: "word",
                playBefore: true,
                silenceBuzz: true,

                timeOut: 15,
                instruction: "repeatables/L18-GP",
                delayBefore: 1000,
                title: "Guided Practice",
                introduction: {
                    title: "Commonly Mispronounced Sounds",
                    subTitle: "Guided Practice",
                    audio: "prompt/gp3"

                }
            },
            p2: {
                type: "DisplayItems",
                subType: "word",
                microphone: true,
                silenceBuzz: true,
                instruction: "repeatables/L18-P1",
                delayBefore: 1000,
                timeOut: 30,
                title: "Blending Practice",
                introduction: {
                    title: "Blending Practice",
                    audio: "prompt/p1-1"
                },
                steps: [
                    {
                        type: "audio",
                        content: "prompt/p1-2",
                        afterDelay: 500
                    }
                ],
                uiBeforeSteps: true
            },
            rio: {
                type: "ReadItOut",
                subType: "sequential",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 40,
                microPhoneConfig: {
                    waitTime: 50000,
                    timeOut: 7000,
                    wrLength: 50
                },
                introduction: {
                    title: "Read it Out!",
                    audio: "prompt/rio"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["exp1", "e1", "e2", "p1-1", "p1-2", "rio", "bp", "gp1", "gp2", "gp3"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["p2"]
        },
        data: "lesson18"
    },

    19: {
        stages: {
            rio: {
                type: "ReadItOut",
                subType: "sequential",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 20,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Common Phrases",
                instruction: "repeatables/L19",
                introduction: {
                    title: "Common Phrases",
                    audio: "prompt/instructions"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e1", "instructions"]
            }]
        },
        review: {
            fromLesson: 18,
            stages: ["p1"]
        },
        data: "lesson19"
    },

    20: {
        stages: {
            rio: {
                type: "ReadItOut",
                subType: "sequential",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                instruction: "repeatables/L20",
                timeOut: 30,
                microPhoneConfig: {
                    waitTime: 35000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Sentence Practices",
                introduction: {
                    title: "Sentence Practices",
                    audio: "prompt/instructions"
                }

            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["e1", "instructions"]
            }]
        },
        review: {
            fromLesson: 18,
            stages: ["p1"]
        },
        data: "lesson20"
    },

    21: {
        stages: {
            l3: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 2 * 60,
                microPhoneConfig: {
                    waitTime: ((2 * 60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                title: "Level 3",
                instruction: "repeatables/L21",
                steps: [
                    {
                        type: "preview",
                        timeOut: 3 * 60,
                        disabled: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l3-2"
                    },
                    {
                        type: "preview",
                        timeOut: 3 * 60
                    },
                    {
                        type: "audio",
                        content: "prompt/l3f-1",
                        uiBefore: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l3f-2",
                        uiBefore: true
                    },
                ],
                introduction: {
                    title: "Fluency Read",
                    subTitle: "Level 3",
                    audio: "prompt/l3-1"
                }

            },
            l4: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                timeOut: 2 * 60,
                microPhoneConfig: {
                    waitTime: ((2 * 60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                instruction: "repeatables/L21",
                title: "Level 4",
                steps: [

                    {
                        type: "preview",
                        timeOut: 3 * 60,
                        disabled: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l4-2"
                    },
                    {
                        type: "preview",
                        timeOut: 3 * 60
                    },
                    {
                        type: "audio",
                        content: "prompt/l4f-1",
                        uiBefore: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l4f-2",
                        uiBefore: true
                    },
                ],
                introduction: {
                    title: "Fluency Read",
                    subTitle: "Level 4",
                    audio: "prompt/l4-1"
                }

            },
            l5: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 2.5 * 60,
                microPhoneConfig: {
                    waitTime: ((2.5 * 60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                instruction: "repeatables/L21",
                title: "Level 5",
                steps: [

                    {
                        type: "preview",
                        timeOut: 3 * 60,
                        disabled: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l5-2"
                    },
                    {
                        type: "preview",
                        timeOut: 3 * 60
                    },
                    {
                        type: "audio",
                        content: "prompt/l5f-1",
                        uiBefore: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l5f-2",
                        uiBefore: true
                    },
                ],
                introduction: {
                    title: "Fluency Read",
                    subTitle: "Level 5",
                    audio: "prompt/l5-1"
                }
            },
            l6: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 3.5 * 60,
                microPhoneConfig: {
                    waitTime: ((3.5 * 60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                instruction: "repeatables/L21",
                title: "Level 6",
                steps: [

                    {
                        type: "preview",
                        timeOut: 3 * 60,
                        disabled: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l6-2"
                    },
                    {
                        type: "preview",
                        timeOut: 3 * 60
                    },
                    {
                        type: "audio",
                        content: "prompt/l6f-1",
                        uiBefore: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l6f-2",
                        uiBefore: true
                    },
                ],
                introduction: {
                    title: "Fluency Read",
                    subTitle: "Level 6",
                    audio: "prompt/l6-1"
                }

            },
            l7: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 5 * 60,
                microPhoneConfig: {
                    waitTime: ((4.5 * 60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                instruction: "repeatables/L21",
                title: "Level 7",
                steps: [

                    {
                        type: "preview",
                        timeOut: 5 * 60,
                        disabled: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l6-2"
                    },
                    {
                        type: "preview",
                        timeOut: 5 * 60
                    },
                    {
                        type: "audio",
                        content: "prompt/l7f-1",
                        uiBefore: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l6f-2",
                        uiBefore: true
                    },
                ],
                introduction: {
                    title: "Fluency Read",
                    subTitle: "Level 7",
                    audio: "prompt/l7f-1"
                }
            },
            l8: {
                type: "ReadItOut",
                subType: "large",
                microphone: true,
                silenceBuzz: true,
                delayBefore: 1000,
                timeOut: 5 * 60,
                microPhoneConfig: {
                    waitTime: ((4.5 * 60) + 20) * 1000,
                    timeOut: 7000,
                    wrLength: 50
                },
                instruction: "repeatables/L21",
                title: "Level 8",
                steps: [

                    {
                        type: "preview",
                        timeOut: 5 * 60,
                        disabled: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l6-2"
                    },
                    {
                        type: "preview",
                        timeOut: 5 * 60
                    },
                    {
                        type: "audio",
                        content: "prompt/l8f-1",
                        uiBefore: true
                    },
                    {
                        type: "audio",
                        content: "prompt/l6f-2",
                        uiBefore: true
                    },
                ],
                introduction: {
                    title: "Fluency Read",
                    subTitle: "Level 8",
                    audio: "prompt/l8f-1"
                }
            },
        },
        audioSets: {
            _: [{
                audio: "https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/{{lessonId}}/prompts/{{key}}.mp3",
                prefix: "prompt/",
                data: ["l3-1", "l3-2", "l3f-1", "l3f-2", "l4-1", "l4-2", "l4f-1", "l4f-2", "l5-1", "l5-2", "l5f-1", "l5f-2", "l6-1", "l6-2", "l6f-1", "l6f-2", "l7f-1", "l8f-1"]
            }]
        },
        review: {
            fromLesson: "self",
            stages: ["prep"]
        },
        data: "lesson21"
    }
};


export const soundLessons = [7, 9, 11, 14, 16]