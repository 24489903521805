import React, { useCallback, useContext, useEffect, useState } from "react";
import { playSound } from "../../utils/playsound";
import styles from "./DisplayItems.module.css";
import clsx from "clsx";
import { Flipped, Flipper } from "react-flip-toolkit";
import Lottie from "react-lottie";
import BeatRead21 from "../../components/common/BeatRead21";
import FloatingObject from "../../components/common/FloatingObjects";
import StatusContext from "../../context/StatusContext";
import UserContext from "../../context/UserContext";
import * as countDownAnimation from "../../lottie/count-down.json";
import { useInstruction } from "../../utils/instruction";
import Button, { SlottedButton } from "../common/Button";
import BaseStage from "../interfaces/BaseStage";
import StageIntroduction from "../introduction/StageIntroduction";
import ItemTable from "../neutral/ItemTable";
import RecordButton from "../neutral/RecordButton";
import useTouchSensor from "../../utils/use-touch-sensor.tsx";
import { cn } from "../../utils/utils.js"
import {
    useKeyboardSensor,
    useMouseSensor,
} from 'react-beautiful-dnd';
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"

    if (/android/i.test(userAgent)) {
        return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }

    return "unknown";
}

import {
    DragDropContext,
    Droppable,
    Draggable,
} from "react-beautiful-dnd";
import wait from "../../utils/wait";
import { skip } from "../../utils/skip";
/**
 *
 * @param {string} word
 * @param type
 * @param syllabicPos
 * @param cancelPos
 * @param validVowels
 * @param micStep
 * @param registerMicrophone
 * @param registerTextInput
 * @param microphone
 * @param micPart
 * @returns {JSX.Element}
 * @constructor
 */
export function WordEditor({
    word,
    syllabicPos,
    cancelPos,
    validVowels,
    micStep,
    registerMicrophone,
    registerTextInput,
    beforeLine,
    micPart,
    steps,
    microphone
}) {
    const { buttonDisabled } = useContext(StatusContext)
    const [pState, setPState] = useState(steps?.[0])
    const [vowels, setVowels] = useState([])
    const [syllableLine, setSyllableLine] = useState()
    const [cancelLine, setCancelLine] = useState()
    const [dragTemporary, setDragTemporary] = useState(true)

    const { formSubmit, disabled, setText } = registerTextInput()
    useEffect(() => {
        if (micPart) {
            setCancelLine(cancelPos)
            setSyllableLine(syllabicPos)
            setVowels(validVowels)
        }
    }, [micPart])

    useEffect(() => {
        if (pState == "next") {
            // Pseudocode for a more stable approach

            let insertions = [];

            // Add syllable line insertion


            // Add vowel parentheses insertions
            vowels.forEach(vowelIndex => {
                insertions.push({ index: vowelIndex, char: '(' });
                insertions.push({ index: vowelIndex + 1, char: ')' });
            });

            if (syllableLine >= 0) {
                insertions.push({ index: syllableLine + 1, char: '|' });
            }

            // Add cancel line insertion
            if (cancelLine >= 0) {
                insertions.push({ index: cancelLine + 1, char: '/' });
            }

            // Sort insertions by original index
            insertions.sort((a, b) => a.index - b.index);

            // Apply insertions considering the shift caused by previous insertions
            let totalInserted = 0;
            let newText = word.split("");
            insertions.forEach(insertion => {
                let adjustedIndex = insertion.index + totalInserted;
                newText.splice(adjustedIndex, 0, insertion.char);
                totalInserted += 1;
            });

            newText = newText.join("");
            formSubmit()
            setText(newText)
        }

    }, [cancelLine, syllableLine, vowels, word, pState])

    const nextStep = useCallback(() => {
        if (steps.indexOf(pState) < (steps.length - 1)) {
            setPState(steps[steps.indexOf(pState) + 1])
        } else {
            setPState("next")
        }
    }, [pState, steps]);

    const onDragEnd = useCallback(async (result) => {
        if (!result.destination && (result.destination?.droppableId === "tools")) return;
        var inp = Number.parseInt(result.destination?.droppableId.replace("drpaddr-", ""))
        var actionType = result.draggableId;
        if (actionType == "cancellation") {
            if (inp == (cancelPos)) {
                await playSound("correct")
                setCancelLine(inp)
                nextStep()
            } else {
                await playSound("wrong")
                setCancelLine(null)
            }
        } else if (actionType == "separator") {
            if (inp == (syllabicPos)) {
                await playSound("correct")
                setSyllableLine(inp)
                nextStep()
            } else {
                await playSound("wrong")
                setSyllableLine(null)
            }
        }

        return true;


    }, [nextStep, cancelPos, syllabicPos]);

    return <DragDropContext onDragEnd={onDragEnd}
        enableDefaultSensors={false}
        sensors={[useMouseSensor, useKeyboardSensor, useTouchSensor]}
    >
        <div className={clsx(styles.wordActions, styles[getMobileOperatingSystem()], 'flex-col md:flex-row')}>

            <span className={styles.word}>
                {
                    word.split("").map((spl, inp) => {
                        return <>
                            <Droppable droppableId={`drpaddr-${inp}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{ display: "flex" }}
                                    >
                                        <span
                                            className={clsx(styles.character, ((snapshot.isDraggingOver && (snapshot.draggingOverWith === "cancellation")) || ((cancelLine == inp) && (cancelLine == cancelPos))) && styles.cancelled, styles.cancelStage, (pState == "vowels") && styles.hoverScalable, ((vowels?.indexOf(inp) !== -1)) && styles.correctChr, "!text-4xl")}
                                            onClick={async () => {
                                                if ((pState == "vowels") && !buttonDisabled) {
                                                    if (validVowels.indexOf(inp) != -1) {
                                                        await playSound("correct")
                                                        var _addState = vowels || []

                                                        _addState.push(inp)
                                                        setVowels([..._addState])


                                                        if (validVowels.every(vl => (_addState.indexOf(vl) !== -1))) {
                                                            if (beforeLine) {
                                                                await playSound(beforeLine)
                                                            }
                                                            nextStep()

                                                        }
                                                    } else {
                                                        await playSound("wrong")
                                                    }
                                                }
                                            }}
                                        // onDragOver={(event) => {
                                        //     if ((pState == "cancel")) {
                                        //         event.stopPropagation();
                                        //         event.preventDefault();
                                        //     }
                                        //     event.preventDefault(); // allows us to drop
                                        //     event.stopPropagation(); // stop it here to prevent it bubble up
                                        // }}
                                        // onDrop={async (e) => {
                                        //     setDragTemporary(false)
                                        //     if(pState == "cancel"){
                                        //         if (inp == (cancelPos)) {
                                        //             await playSound("correct")
                                        //             await wait(1000)
                                        //             nextStep()
                                        //
                                        //         } else {
                                        //             await playSound("wrong")
                                        //             e.preventDefault()
                                        //             setCancelLine(null)
                                        //
                                        //         }
                                        //     }
                                        // }}
                                        // onDragEnter={(e) => {
                                        //     if (pState == "cancel") {
                                        //         setDragTemporary(true)
                                        //         setCancelLine(inp)
                                        //     }
                                        //     e.preventDefault();
                                        //     e.stopPropagation();
                                        // }}
                                        // onDragLeave={() => {
                                        //     if ((dragTemporary) && (pState == "cancel")) {
                                        //         setCancelLine(null)
                                        //         setDragTemporary(false)
                                        //     }
                                        // }}
                                        >{spl}</span>
                                        <div

                                            // onDrop={async (e) => {
                                            //     setDragTemporary(false)
                                            //     if ((pState == "syllable")) {
                                            //         if (inp == (syllabicPos)) {
                                            //             await playSound("correct")
                                            //             nextStep()
                                            //         } else {
                                            //             await playSound("wrong")
                                            //             e.preventDefault()
                                            //             setSyllableLine(null)
                                            //         }
                                            //
                                            //     } else {
                                            //
                                            //         e.preventDefault()
                                            //         setSyllableLine(null)
                                            //
                                            //     }
                                            // }
                                            // }
                                            // onDragOver={(event) => {
                                            //     if ((pState == "syllable")) {
                                            //         event.stopPropagation();
                                            //         event.preventDefault();
                                            //     }
                                            //     event.preventDefault(); // allows us to drop
                                            //     event.stopPropagation(); // stop it here to prevent it bubble up
                                            // }}
                                            // onDragEnter={(e) => {
                                            //     if ((pState == "syllable")) {
                                            //
                                            //         setDragTemporary(true)
                                            //         setSyllableLine(inp)
                                            //     }
                                            //     e.preventDefault();
                                            //     e.stopPropagation();
                                            // }}
                                            //
                                            // onDragLeave={() => {
                                            //     if ((dragTemporary) && ((pState == "syllable"))) {
                                            //         setSyllableLine(null)
                                            //         setDragTemporary(true)
                                            //     }
                                            // }}
                                            className={clsx(styles.theDropTarget, (((snapshot.isDraggingOver) && (snapshot.draggingOverWith === "separator")) || (
                                                (syllableLine == inp) && (syllableLine == syllabicPos)
                                            )) && styles.dragging)}
                                        ></div>
                                    </div>
                                )
                                }

                            </Droppable>



                        </>
                    })
                }
            </span>
            <Droppable isDropDisabled={true} droppableId={`tools`}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {(pState == "cancel") && <Draggable key={"cancellation"} draggableId={"cancellation"} index={0}>
                            {(provided, snapshot) => (
                                <div className={clsx(styles.separatorLine, styles.cancellation)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <div className={styles.sln}></div>
                                </div>
                            )}

                        </Draggable>}



                        {((pState == "syllable")) && <Draggable key={"separator"} draggableId={"separator"} index={0}>
                            {(provided, snapshot) => (
                                <div className={styles.separatorLine}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <div className={styles.sln}></div>
                                </div>
                            )}
                        </Draggable>}
                    </div>
                )}
            </Droppable>


            {
                ((pState == "next") && !micPart) && <>
                    <div style={{ marginTop: 60 }} />
                    <Button icon={"nextarrow"} contentSize={"large"} title={"Continue"} onClick={() => {
                        formSubmit()
                        /**
                         * Button will be disabled whilist audio, good method to keep everything together,
                         */
                    }} />
                </>
            }

            {
                micPart && microphone && <div className={clsx(styles.micAndWord)} style={{ flex: 0 }}>
                    <RecordButton
                        {...registerMicrophone(microphone)}
                    /></div>
            }
        </div>
    </DragDropContext>
}


function CountDownItem({ amount, onEnded }) {

    useEffect(() => {
        (async () => {
            await soundManager.playSound("background", "enticing")
            onEnded()
        })()



        return () => {
            soundManager.cleanChannel("background")
        }

    }, [])

    return <Lottie
        loop={false}
        options={{
            loop: false,
            autoplay: true,
            animationData: countDownAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid",
            },
        }}
        width={600}
    />
}



class DisplayItems extends BaseStage {
    state = {}
    /**
     *
     * @param {string} word
     * @returns {JSX.Element}
     */
    renderWord(word, popUp, preserve, trialType, isCorrected, tryAgainRef, lessonId) {


        let popUpText;
        if (popUp) {
            
            switch (word) {
                case 'ae':
                    popUpText = popUp.split("").map((letter, index) => (
                    
                        <tspan key={index} fill={(index == 0 || index==1|| index==8)?'#2664B8':'black' }>
                            {letter}
                        </tspan>
                    ))
                    break;
            
                
            
                case 'ei':
                    popUpText = popUp.split("").map((letter, index) => (
                    
                        <tspan key={index} fill={(index == 0 || index==1)?'#2664B8':'black' }>
                            {letter}
                        </tspan>
                    ))
                    break;
            
               
                case 'ie':
                    popUpText = popUp.split("").map((letter, index) => (
                    
                        <tspan key={index} fill={(index == 1 || index==2|| index==11 ||index ==12)?'#2664B8':'black' }>
                            {letter}
                        </tspan>
                    ))
                    break;
            
                default:
                   popUpText = popUp.split("").map((letter, index) => (
                    
                        <tspan key={index} fill={word.includes(letter)?'#2664B8':'black' }>
                            {letter}
                        </tspan>
                    ))
                    break;
            }
            var scaleX = popUp.length<=6? 1:popUp.length/6.5
         scaleX>=2?scaleX=1.75:scaleX;
        }

        const getViewBox = () => {
            if (scaleX>1) {
              return `${-26*scaleX} 0 ${120*scaleX} 100`;
            }
            return "0 0 100 100";
          };

        var wordSize = "large"
        if (word.length > 7) {
            wordSize = "large"
        } else if (word.length > 4) {
            wordSize = "medium"
        } else {
            wordSize = "small"
        }
        if (preserve) {
            return (
                <h1 className={styles[wordSize]}>{word}</h1>
            )
        }

        return word.includes("-") ?
            <h1 className={clsx(styles.splittedWord, styles[wordSize])}>{word.split("-").map((itm, i) => <>{(i > 0) && <b></b>}<span
                style={{ '--delay': i + 1 }} dangerouslySetInnerHTML={{ __html: itm }}></span></>)}</h1>
            : (word.includes("*") ?
                <h1 className={clsx(styles.splittedWord, styles[wordSize])}>{word.split("*").map((itm, i) => <>{(i > 0) && <b>*</b>}<span
                    style={{ '--delay': i + 1 }} dangerouslySetInnerHTML={{ __html: itm }}></span></>)}</h1> :

                <>
                    <div className={cn(
                        "relative",

                    )}>
                        {

                            ((lessonId == 8) &&((trialType != 'first'&& (tryAgainRef.current == true ||isCorrected.current==false)) || (trialType == 'first'&&isCorrected.current == false && tryAgainRef.current == false)))&&

                            <svg
                                fill="#000000"
                                height="300"
                                width={scaleX>1?scaleX*350:550}
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox={getViewBox()}
                                xmlSpace="preserve"
                                className={cn(
                                    "absolute bottom-0 left-0 md:bottom-0 md:left-0 lg:left-0 lg:bottom-0",
                                    // style.bubble
                                )}>
                                <g transform={`scale(${scaleX}, 1)`}>
                                
                                    <path d="M9.49,73.833c-1.493,0-2.943-0.24-4.309-0.713l-3.113-1.077l2.392-2.265c3.165-2.997,3.964-6.455,4.016-9.046
		C3.004,54.666,0,47.097,0,39.334c0-19.023,17.645-34.5,39.333-34.5s39.334,15.477,39.334,34.5
		c0,19.022-17.646,34.498-39.334,34.498c-6.458,0-12.827-1.399-18.505-4.057C18.689,71.289,14.366,73.833,9.49,73.833z
		 M20.361,65.078l1.148,0.581c5.397,2.729,11.561,4.173,17.824,4.173c19.483,0,35.334-13.682,35.334-30.498
		c0-16.818-15.851-30.5-35.334-30.5S4,22.516,4,39.334c0,6.99,2.814,13.823,7.925,19.238l0.52,0.552l0.024,0.757
		c0.087,2.72-0.401,6.407-2.818,9.951c4.63-0.074,8.89-3.298,9.705-3.95L20.361,65.078z"/>
                                </g>
                                <text className={scaleX>1?styles.popuplarge:styles.popup} x={scaleX*40} y="45" textAnchor="middle" fontWeight="bold">
                                    {popUpText}
                                </text>
                            </svg>}

                        <h1 className={styles[wordSize]} dangerouslySetInnerHTML={{ __html: word }}></h1>
                    </div>
                </>

            )
    }


    render() {
     
        const {
            status,
            data,
            lessonData,
            current,
            stopPresentable,
            nextItem,
            nextPage,
            currentPage,
            repeatId,
            //bek
            trialType,
            isCorrected,
            tryAgainRef,
            lessonId,

            trialId,
            countDown,
            stageDetails,
            phonemeResults,
            ageGroup
        } = this.props
        const isPageChanging = ((nextItem != null) && (nextItem !== current));
        const isGroupChanging = ((nextPage != null) && (nextPage !== currentPage));
        // window.lastCatchedData = data
        // window.lastCatchedCurrent = current
        const currentRef = data[current].ref?.toUpperCase?.()
        const tableInfo = (stageDetails.table?.type == "multi") && stageDetails.table?.data.find(itm => itm.table.find(cols => cols.indexOf(current) != -1))
        const isLastStep = isGroupChanging && stageDetails.lastStep
        return <StageIntroduction triggerLastStep={async () => {
            if (stageDetails.lastStep.type === "images") {
                this.setState({ page: "images", content: stageDetails.lastStep.content })
            }
        }} isLastStep={isLastStep} stageDetails={stageDetails} onShown={async () => {
            if (skip("displayItems")) {
                if (!stageDetails.uiBeforeSteps) {
                    this.setState({ page: "lesson" })
                }
                this.props.controls.initiateQuestions()
                return
            }
            if (stageDetails.uiBeforeSteps) {
                this.setState({ page: "lesson" })
                await wait(1000)

            }
            await wait(500)
            if (stageDetails.steps) {
                const { steps } = stageDetails;
                for (let i = 0; i < steps.length; i++) {
                    var step = steps[i]
                    if (step.type == "display") {
                        this.setState({ page: "display", content: step.content, wBegin: false })
                        await wait(600)
                    } else if (step.type == "introduction") {
                        this.setState({ page: "introduction", title: step.title, subTitle: step.subTitle, wBegin: false })
                        await wait(600)
                    } else if (step.type == "rawDisplay") {
                        this.setState({ page: "rawDisplay", content: step.content, wBegin: false })
                        await wait(600)

                    } else if (step.type == "countShowDown") {
                        await (new Promise((resolve, reject) => {
                            this.setState({
                                page: "countShowDown", amount: step.content, callback() {
                                    resolve()
                                }
                            })
                        }))

                    } else if (step.type == "images") {
                        this.setState({ page: "images", content: step.content })
                        await wait(600)
                    } else if (step.type == "setTitle") {
                        this.setState({ interMediateTitle: step.content })
                        await wait(600)
                    } else if (step.type == "audio") {
                        await soundManager.playSound("instruction", step.content)

                        if (step.wBegin) {
                            await (new Promise((resolve, reject) => {
                                this.setState({
                                    wBegin: true, callback() {
                                        resolve()
                                    }
                                })
                            }))
                        }
                        await wait(step.afterDelay)
                    } else if (step.type == "video") {

                        const promise = (new Promise((resolve, reject) => {
                            this.setState({
                                page: "video", content: step.content.replace("[[ageGroup]]", ageGroup), callback() {
                                    resolve()
                                }
                            })
                        }))
                        setTimeout(() => {
                            //check if video element which is id of videoPlayerItem exists, if it does, then play it
                            const videoElement = document.getElementById("videoPlayerItem")
                            if (videoElement) {
                                videoElement.play();
                                // register an onclick event and if clicked, play the video
                                videoElement.onclick = function () {
                                    videoElement.play();
                                }
                            }
                        }, 500)
                        await promise
                    } else if (step.type == "stepLister") {
                        this.setState(({
                            page: "step",
                            content: [...((((this.state.page === "step") && !(step.clearPrevious)) ? this.state.content : []) || []),
                            { detail: (step.content), step: step.step }]
                        }))
                    }

                }
            }
            if (!stageDetails.uiBeforeSteps) {
                this.setState({ page: "lesson" })
            }
            this.props.controls.initiateQuestions()
        }}>
            <div className={clsx(styles.container, status && styles[status])}>
                <div className={styles.titleContainer}>
                    {(tableInfo?.title || stageDetails.title || this.state.interMediateTitle) ? <h1 className={styles.title}>{tableInfo?.title || stageDetails.title || this.state.interMediateTitle}</h1> : <h1></h1>}

                    {
                        this.state.page === "step" && <>
                            <div className={styles.stepsListed}>
                                {
                                    this.state.content.map((step, i) => <div key={step.step} className={styles.stepItem}>
                                        <span className={styles.stepNum}>STEP {step.step}</span>
                                        <div className={styles.stepDetails} dangerouslySetInnerHTML={{ __html: step.detail }}>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </>
                    }
                    {
                        this.state.page === "rawDisplay" && <>
                            <div className={clsx(styles.sequences)} style={{ flexDirection: "column" }}>
                                <div className={clsx(styles.sequences)} style={{ paddingBottom: 10 }} dangerouslySetInnerHTML={{ __html: this.state.content }}>

                                </div>
                                {
                                    this.state.wBegin && <>
                                        <SlottedButton icon={"nextarrow"} contentSize={"large"} title={"Begin"} onClick={() => {
                                            this.state.callback()
                                        }} />
                                    </>
                                }
                            </div>

                        </>
                    }
                    {
                        this.state.page === "introduction" && <>
                            <div className={clsx(styles.sequences, styles.introductionCap)}>
                                <div className={clsx(styles.stageTitle)}>
                                    {
                                        this.state.title && <h1 className={clsx(this.state.title?.length <= 7 && styles.small)}>{this.state.title}</h1>
                                    }
                                    {
                                        this.state.subTitle && <h2>{this.state.subTitle}</h2>
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {
                        this.state.page === "display" && <>
                            <div className={clsx(styles.sequences)}>
                                <div className={clsx(styles.micAndWord, stopPresentable && styles.noAnimation)}>
                                    {this.renderWord(this.state.content)}
                                </div>
                            </div>
                        </>
                    }
                    {
                        this.state.page === "countShowDown" && <div className={clsx(styles.sequences)} style={{ padding: 0, margin: 0 }}>
                            <CountDownItem amount={this.state.amount} onEnded={() => {
                                this.state.callback();
                            }} />
                        </div>
                    }
                    {
                        this.state.page === "video" && <>
                            <video id={"videoPlayerItem"} controls={true} autoPlay={true} autoBuffer={true}
                                src={this.state.content} className={styles.videoPresentation} onEnded={() => {
                                    this.state.callback()
                                }
                                } />
                        </>
                    }
                    {
                        this.state.page === "images" && <>
                            <div className={styles.presentList}>
                                {
                                    this.state.content.map(itm => {
                                        return <img src={itm} />
                                    })
                                }

                            </div>
                        </>
                    }
                    {
                        this.state.page == "lesson" && <div className={styles.separator}><>
                            {
                                stageDetails.subType == "phonetics" &&
                                <WordEditor
                                    microphone={data[current].microphone || stageDetails.microphone}
                                    {...data[current]}
                                    type={"syllable"}
                                    word={data[current].text}
                                    registerMicrophone={this.props.controls.registerMicrophone}
                                    registerTextInput={this.props.controls.registerTextInput}

                                />
                            }
                            {
                                stageDetails.table?.data && <>
                                    {(stageDetails.table?.type == "multi") &&
                                        <ItemTable small={stageDetails.table?.isSmall} selected={current} tableMap={tableInfo?.table} namingMap={data} />

                                    }
                                    {
                                        (stageDetails.table?.type == "simple") && <div
                                            className={styles.wordColumns}>{(stageDetails.table?.data.find(ca => (ca.indexOf(current) != -1) || (ca.indexOf(currentRef) != -1))).map(rw =>
                                                <div
                                                    className={clsx(styles.wordItem, ((rw == current) || (currentRef == rw)) && styles.selected)}>{(data[rw].text)}</div>)}</div>
                                    }

                                    {
                                        (stageDetails.table?.type == "complex") &&
                                        <ItemTable small={true} selected={current} tableMap={stageDetails.table?.data}
                                            namingMap={data} />
                                    }


                                </>
                            }
                            {
                                stageDetails.subType == "largeWord" &&
                                <div className={clsx(styles.sequences, isPageChanging && styles.hiding)}>
                                    <div className={clsx(styles.micAndWord, stopPresentable && styles.noAnimation, styles.largeSize)}>
                                        {
                                            Array.isArray(data[current].text) ? <>
                                                <div className={styles.soundButtons}>
                                                    {
                                                        data[current].text.map(char => {
                                                            return <button onClick={async () => {
                                                                await window.soundManager.playImmediate(`char/${char}`)
                                                            }}>{char}</button>
                                                        })
                                                    }
                                                </div>
                                            </> : this.renderWord(data[current].text)
                                        }
                                        {
                                            (data[current].microphone || stageDetails.microphone) && <RecordButton
                                                {...(this.props.controls.registerMicrophone(data[current].microphone || stageDetails.microphone))}
                                                {...(stageDetails.micDisabled ? { contextuallyDisabled: true } : {})}

                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                stageDetails.subType == "word" &&
                                <div className={clsx(styles.sequences, isPageChanging && styles.hiding)}>
                                    <div className={clsx(styles.micAndWord, stopPresentable && styles.noAnimation)}>
                                        {
                                            Array.isArray(data[current].text) ? <>
                                                <div className={styles.soundButtons}>
                                                    {
                                                        data[current].text.map(char => {
                                                            return <button onClick={async () => {
                                                                await window.soundManager.playImmediate(`char/${char}`)
                                                            }}>{char}</button>
                                                        })
                                                    }
                                                </div>
                                            </> : this.renderWord(data[current].text, data[current].popUp, data[current]?.preserve, trialType, isCorrected, tryAgainRef, lessonId)
                                        }
                                        {
                                            (data[current].microphone || stageDetails.microphone) && <RecordButton
                                                {...(this.props.controls.registerMicrophone(data[current].microphone || stageDetails.microphone))}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                stageDetails.subType == "images" && <>
                                    <div className={clsx(styles.imageList, isPageChanging && styles.hiding)} key={current}>
                                        {
                                            data[current].imgs.map((seq, i) => {
                                                return <img style={{ '--delay': (i + 1) * 0.5, }} className="border-2 rounded-xl border-stone-900/60 bg-stone-600/10" src={seq} key={seq} />
                                            })
                                        }
                                    </div>
                                </>
                            }
                            {
                                stageDetails.subType == "imagesText" && <>
                                    <div className={clsx(styles.imageText, isPageChanging && styles.hiding)} key={current}>
                                        <img src={stageDetails.img.replace("{{key}}", current.toLowerCase())} />
                                        <span>{data[current].text}</span>
                                    </div>
                                </>
                            }
                            {
                                stageDetails.subType == "charImages" && <>
                                    <div className={cn(styles.imageText, isPageChanging && styles.hiding, " !flex items-center gap-2 w-full !flex-row ")} key={current}>
                                        {
                                            ["a1", "e1", "i1", "o1", "u1"].map(key => (
                                                <img src={
                                                    `https://d3vx0tne0vxeo1.cloudfront.net/assets/images/lesson/3/${key}.svg`
                                                } className="border border-stone-600 bg-stone-200 px-4 rounded-md py-6 hover:bg-stone-300 transition-all duration-200" onClick={async () => {
                                                    if (countDown !== null) {
                                                        if (status === "processing") {
                                                            return
                                                        }
                                                        this.props.controls.registerTextInput().setText(key)
                                                        this.props.controls.registerTextInput().formSubmit()
                                                    }
                                                }} />
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                stageDetails.subType == "characters" && <div className={styles.flipperContainer}>
                                    <Flipper flipKey={data[current].text}>
                                        <div
                                            className={clsx(styles.characters, isGroupChanging && styles.hiding, stageDetails.boxed && styles.boxed)}>
                                            {
                                                ((typeof data[current].text == "string") ?
                                                    data[current].text.split("") : data[current].text)
                                                    .map((pron, i) => {
                                                        return <Flipped key={pron} flipId={pron}>
                                                            <div className={cn(styles.item, "border-2 rounded-xl border-stone-900/60 bg-stone-600/10 px-4")}
                                                                style={{ '--delay': (i + 1) * 0.8 }}>{pron}</div>
                                                        </Flipped>
                                                    })
                                            }
                                        </div>
                                    </Flipper>
                                </div>
                            }
                            {
                                stageDetails.subType === "letterSound" && <LetterSound data={data} current={current} controls={this.props.controls} status={status} countDown={countDown} />
                            }
                        </>
                        </div>
                    }


                </div>

                <BeatRead21 />
                <FloatingObject isHiding={isGroupChanging} color={"rgba(78,84,200, 0.2)"} status={status} />
            </div>
        </StageIntroduction>
    }
}


function LetterSound({ data, current, controls, status, countDown }) {
    /**
     * @type {string[]}
     */
    let letters = data[current].text.split("")
    letters = letters.length === 5 ? letters.slice(1) : letters
    const key = data[current].key.split("")[1].toLowerCase()
    const { formSubmit, setText, } = controls.registerTextInput()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(window.innerWidth < 768)
    }, [])


    return (
        <div className="flex flex-col items-center gap-20">
            <div className={
                cn("flex items-center gap-2 lg:gap-10 md:gap-10 ")
            }>
                {
                    letters.map(letter => {
                        return (
                            <div key={letter} className="text-4xl scale-100 md:scale-125 lg:scale-150 !mx-2 md:!mx-5 relative cursor-pointer flex items-center justify-center" onClick={() => {
                                if (countDown !== null) {
                                    setText(letter.toUpperCase())
                                    formSubmit()
                                }
                            }}>
                                <svg width={
                                    isMobile ? "70" : "125"
                                } height={
                                    isMobile ? "70" : "125"
                                } className={cn(" hover:brightness-90 transition-all  duration-150 ease-in-out", status === "processing" && "animate-pulse")} viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_5_2)">
                                        <path d="M59.2937 104.259C85.4132 104.259 106.587 83.6833 106.587 58.3016C106.587 32.9199 85.4132 12.3439 59.2937 12.3439C33.1741 12.3439 12 32.9199 12 58.3016C12 83.6833 33.1741 104.259 59.2937 104.259Z" fill="#FFCD02" />
                                        <path d="M105.917 61.9852L75.8336 31.9022C75.8336 31.9022 61.4727 29.4364 46.224 31.9022C30.9754 34.368 32.2773 50.7411 32.2773 50.7411L35.118 74.4524L64.2541 103.589C85.5785 100.117 102.425 83.3097 105.917 61.9852Z" fill="#A78D01" fill-opacity="0.63" />
                                        <path d="M62.5 108C90.3904 108 113 85.3904 113 57.5C113 29.6096 90.3904 7 62.5 7C34.6096 7 12 29.6096 12 57.5C12 85.3904 34.6096 108 62.5 108Z" fill="#FFCD02" />
                                        <path d="M112.329 65.726L82.2463 35.643C82.2463 35.643 67.8854 33.1771 52.6367 35.643C37.3881 38.1088 38.69 54.4818 38.69 54.4818L41.5307 78.1932L70.6668 107.329C91.9912 103.857 108.838 87.0504 112.329 65.726Z" fill="#A78D01" fill-opacity="0.63" />
                                        <path d="M62.2328 93.037C82.0069 93.037 98.037 77.0069 98.037 57.2328C98.037 37.4587 82.0069 21.4286 62.2328 21.4286C42.4587 21.4286 26.4286 37.4587 26.4286 57.2328C26.4286 77.0069 42.4587 93.037 62.2328 93.037Z" fill="url(#paint0_linear_5_2)" />
                                        <path d="M65.4392 93.037C85.2133 93.037 101.243 77.0069 101.243 57.2328C101.243 37.4587 85.2133 21.4286 65.4392 21.4286C45.665 21.4286 29.6349 37.4587 29.6349 57.2328C29.6349 77.0069 45.665 93.037 65.4392 93.037Z" fill="url(#paint1_linear_5_2)" />
                                        <path d="M11.0016 57.9128C11.0005 58.0423 11 58.1719 11 58.3016C11 62.7403 11.6329 67.0339 12.8154 71.1022C18.7813 92.9446 38.7657 109 62.5 109C90.9427 109 114 85.9427 114 57.5C114 29.0573 90.9427 6 62.5 6C34.0573 6 11 29.0573 11 57.5C11 57.6377 11.0005 57.7753 11.0016 57.9128Z" stroke="url(#paint2_linear_5_2)" stroke-width="2" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_5_2" x="0.7" y="0.7" width="123.6" height="123.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="5" />
                                            <feGaussianBlur stdDeviation="4.65" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.888981 0 0 0 0 0.581386 0 0 0 0 0.358645 0 0 0 1 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_2" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_2" result="shape" />
                                        </filter>
                                        <linearGradient id="paint0_linear_5_2" x1="62.5" y1="7" x2="24.3725" y2="82.75" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FFD700" />
                                            <stop offset="1" stop-color="#C7A802" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_5_2" x1="65.4392" y1="21.4286" x2="38.407" y2="75.1349" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#487EC9" />
                                            <stop offset="1" stop-color="#4B80CA" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_5_2" x1="65.4392" y1="21.4286" x2="65.4392" y2="93.037" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.892834" stop-color="#F4D11A" />
                                            <stop offset="1" stop-color="#DBE5F4" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <p className="absolute text-white text-5x text-center top-[27%]" style={{
                                    fontFamily: "century-gothic",
                                    fontWeight: "bold"
                                }}>
                                    {
                                        letter === "q" ? <svg width="28" className="mt-[10px]" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0117 0.492188H19.793V25.6133C19.793 26.1656 19.3453 26.6133 18.793 26.6133H17.0117C15.9071 26.6133 15.0117 25.7179 15.0117 24.6133V17.5957C14.0742 18.4863 13.1309 19.1309 12.1816 19.5293C11.2441 19.916 10.2246 20.1094 9.12305 20.1094C6.65039 20.1094 4.51172 19.1543 2.70703 17.2441C0.902344 15.3223 0 12.9375 0 10.0898C0 7.13672 0.873047 4.7168 2.61914 2.83008C4.36523 0.943359 6.48633 0 8.98242 0C10.1309 0 11.209 0.216797 12.2168 0.650391C13.2246 1.08398 14.1562 1.73438 15.0117 2.60156V0.492188ZM9.9668 4.42969C8.47852 4.42969 7.24219 4.95703 6.25781 6.01172C5.27344 7.05469 4.78125 8.39648 4.78125 10.0371C4.78125 11.6895 5.2793 13.0488 6.27539 14.1152C7.2832 15.1816 8.51953 15.7148 9.98438 15.7148C11.4961 15.7148 12.75 15.1934 13.7461 14.1504C14.7422 13.0957 15.2402 11.7188 15.2402 10.0195C15.2402 8.35547 14.7422 7.00781 13.7461 5.97656C12.75 4.94531 11.4902 4.42969 9.9668 4.42969Z" fill="white" />
                                            <path d="M15.705 23.8499L24.0656 17.2454C24.9543 16.5894 26.2065 16.7781 26.8625 17.6667L27.5006 18.5312C28.1799 19.4515 27.9503 20.7531 26.9972 21.3855L19.5901 26.3003C19.4262 26.4091 19.2339 26.4671 19.0372 26.4671H18.6747L15.705 23.8499Z" fill="white" />
                                        </svg>


                                            : <span className="z-10">
                                                {letter}
                                            </span>
                                    }
                                </p>
                            </div>
                        )
                    })
                }
            </div>
            <Button isDisabled={countDown === null} onClick={async () => {
                await window.soundManager.playImmediate(`p1/part1-${key}`)
            }} icon={"repeat"} border={"white"} background={"green"} title={`Repeat`} />
        </div>
    )
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const { user } = useContext(UserContext);
        useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
        return <Component {...props} ageGroup={
            user.grade ? (Number.parseInt(user.grade) > 5 ? "older" : "younger") : "older"
        } />;
    }
}


export default withMyHook(DisplayItems)
