
import styles from "./Colorful.module.css"
import clsx from "clsx";
import { RecordIcon, WrongIcon, CorrectIcon } from "../../resources/svgs";
import { genColor, randomIntFromInterval } from "../../utils/common";
import FloatingObject from "../../components/common/FloatingObjects";
import Lottie from "react-lottie";
import * as animationData from "../../lottie/processing.json";
import BeatRead21 from "../../components/common/BeatRead21";
import BaseStage from "../interfaces/BaseStage";
import {useWindowSize} from "react-use";
import wait from "../../utils/wait";
import {useInstruction} from "../../utils/instruction";
import { cn } from "../../utils/utils.js"
import React from "react";



class Colorful extends BaseStage {
  async componentDidMount() {
    await wait(1000)
    this.props.controls.initiateQuestions()
  }

  render() {

    const {status, data, current, nextItem, trialType, isCorrected, tryAgainRef, lessonId, repeatId, trialId, countDown, stageDetails: {subType}, windowSize:{width} } = this.props
    const isPageChanging = ((nextItem != null) && (nextItem !== current));
let popUpText;
    if (data[current].popup) {
            if(data[current].text == "Qu" ){
              popUpText = data[current].popup.split("").map((letter, index) => (
                    
                <tspan key={index} fill={(index == 0 || index==1)?'#2664B8':'white' }>
                    {letter}
                </tspan>
            ))
            }else{

              popUpText = data[current].popup.split("").map((letter, index) => (
               
                   <tspan key={index} fill={data[current].text.includes(letter)?'#2664B8':'white' }>
                       {letter}
                   </tspan>
               ))
            }      

  }


    return <div style={{ display: "flex", flexDirection: "column", alignSelf: "stretch", flex: 1 }}><div style={{
      "--hexcolor":
          status === "pass" ? "#16a085" : status === "fail" ? "#af4154" :
              genColor(Object.keys(data).indexOf(current))
    }} className={clsx(styles.container, (status && status != "started") && styles.performing, styles[subType||"normal"], isPageChanging && styles.isPageChanging)} key={current}>
      {
        subType === "normal" && <>
            <div className={styles.left} >
            
              <span>{data[current].example}</span>

            </div>

            <div className={styles.right} >
           
              <span>{data[current].text}</span>
              <FloatingObject white={true} />
            </div>
            <div className={styles.actionIndicator}>
              {
                  (status == "recording") && <RecordIcon />
              }
              {
                  (status == "processing") &&  <Lottie
                      loop={true}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                      }}
                      width={60}
                      height={60}
                  />
              }
              {
                  status == "pass" && <CorrectIcon />
              }
              {
                  status== "fail" && <WrongIcon />
              }


            </div>
            <BeatRead21 iconType={(width > 900) ? "white" : "normal"}/>
          </>
      }
      {
          subType === "half" && <>
            <div className={styles.top}>
            <div className={cn(
                        "relative",

                    )}>
            {
               ((lessonId==2) &&((trialType != 'first'&& (tryAgainRef.current == true ||isCorrected.current==false)) || (trialType == 'first'&&isCorrected.current == false && tryAgainRef.current == false)))&&
            <svg
                                fill="#000000"
                                height="400"
                                width="550"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="-35 0 100 110"
                                xmlSpace="preserve"
                                className={
                                  cn(

                                    "absolute bottom-0 left-0 md:bottom-0 md:left-0 lg:left-0 lg:bottom-0"
                                  )
                                }
                                  
                                >
                                <g>
                                
                                    <path d="M9.49,73.833c-1.493,0-2.943-0.24-4.309-0.713l-3.113-1.077l2.392-2.265c3.165-2.997,3.964-6.455,4.016-9.046
		C3.004,54.666,0,47.097,0,39.334c0-19.023,17.645-34.5,39.333-34.5s39.334,15.477,39.334,34.5
		c0,19.022-17.646,34.498-39.334,34.498c-6.458,0-12.827-1.399-18.505-4.057C18.689,71.289,14.366,73.833,9.49,73.833z
		 M20.361,65.078l1.148,0.581c5.397,2.729,11.561,4.173,17.824,4.173c19.483,0,35.334-13.682,35.334-30.498
		c0-16.818-15.851-30.5-35.334-30.5S4,22.516,4,39.334c0,6.99,2.814,13.823,7.925,19.238l0.52,0.552l0.024,0.757
		c0.087,2.72-0.401,6.407-2.818,9.951c4.63-0.074,8.89-3.298,9.705-3.95L20.361,65.078z"/>
                                </g>
                                <text className={styles.popup} x='40' y="45" textAnchor="middle">
                                    {popUpText}
                                </text>
                            </svg>}
              <span>{data[current].text}</span>
            </div>
            </div>

            <FloatingObject white={true} color={"rgba(255, 255, 255, 0.2)"} />
            <BeatRead21 iconType={"white"}/>
          </>
      }


    </div></div>
  }
}

function withMyHook(Component) {
  return function WrappedComponent(props) {
    useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
    return <Component {...props} />;
  }
}


export default withMyHook(Colorful)